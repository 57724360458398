<template>
  <div>
    <v-btn :class="className"
           :depressed="depressed"
           outlined
           :plain="plain"
           @click="readPublicData">
      {{ label }}
    </v-btn>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  props: {
    label: { type: String, default: "Use ID" },
    depressed: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    plain: { type: Boolean, default: false },
    className: { type: String, default: "white--text blue-color" },
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        name: "",
        dob: "",
        gender: "",
        nationality: "",
        nationality_code: "",
        id_number: "",
        card_number: "",
        mobile: "",
        mobile2: "",
        email: "",
        email2: "",
        passport_number: "",
        isEmiratesIdCheck: true,
      },
    };
  },
  watch: {},
  mounted() {},
  methods: {
    checkEIDStatus(){
      this.$http
          .get("http://localhost:9000/api/Service/status")
          .then((response) => {
            if(response.status === 200 && response.data.status === true) {
              this.readPublicData();
            }else{
              console.log("restart eid service call");
              this.restartEIDService();
            }
          })
          .catch((error) => {
            console.log(error);
            this.restartEIDService();
          });
    },
    restartEIDService(){
      this.$http
          .get("http://localhost:9000/api/Service/restart")
          .then((response) => {
            if(response.status === 200 && response.data.status === true) {
              this.readPublicData();
            }else{
              this.showError("Please unplug and plugin again Toolkit, or restart your PC");
            }
          })
          .catch(() => {
            this.hideLoader();
            this.showError("Please run the emirates id reader service");
          });
    },
    readPublicData() {
      this.showLoader("Loading");
      this.$http
        .get("http://localhost:9000/api/values")
        .then((response) => {
          this.hideLoader();
          if (response.data === "Failed to establish connection with smartcard") {
            this.showError(response.data);
          } else {
            let parsedData = JSON.parse(response.data);
            let nameSplitList = parsedData.full_name.split(",");
            let finalNameList = [];
            nameSplitList.forEach((item) => {
              if (item) {
                finalNameList.push(item);
              }
            });

            let firstName = "";
            let lastName = "";
            let fullName = "";

            if (finalNameList && finalNameList.length > 0) {
              finalNameList.forEach((item, index) => {
                if (index == 0) {
                  firstName = item;
                } else {
                  lastName =
                    lastName +
                    item +
                    (index + 1 >= finalNameList.length ? "" : " ");
                }

                fullName =
                  fullName +
                  item +
                  (index + 1 >= finalNameList.length ? "" : " ");
              });
            }

            fullName = fullName.trim();
            lastName = lastName.trim();
            firstName = firstName.trim();

            this.user.first_name = firstName;
            this.user.last_name = lastName;
            this.user.name = fullName;

            this.user.mobile = "+" + parsedData.mobile;
            this.user.mobile2 = parsedData.mobile2;

            this.user.email = parsedData.email;
            this.user.email2 = parsedData.email2;

            this.user.passport_number = parsedData.passport_no;
            this.user.id_number = parsedData.id_number;
            this.user.card_number = parsedData.card_number;
            // this.user.image = parsedData.img_byte;

            // const file = new File(
            //   ["data:image/jpeg;base64," + parsedData.img_byte],
            //   "image.jpeg",
            //   {
            //     type: "image/jpeg",
            //   }
            // );

            // this.user.image = file;

            if (parsedData.dob) {
              this.user.dob = moment(parsedData.dob, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              );
            }

            if (parsedData.gender == "M" || parsedData.gender == "m") {
              this.user.gender = "Male";
            } else if (parsedData.gender == "F" || parsedData.gender == "f") {
              this.user.gender = "Female";
            }

            let country = null;

            if (parsedData.nationality_code) {
              country = this.countries.find(
                (item) => item.iso_3 == parsedData.nationality_code
              );
            }

            if (country) {
              this.user.country_id = country.id;
            }

            this.user.id_proof_type_id = 32;
            this.user.id_proof_number = parsedData.id_number;

            this.user.nationality = parsedData.nationality;
            this.user.nationality_code = parsedData.nationality_code;
            this.user.isEmiratesIdCheck = true;
            this.$emit("data", this.user);
          }
        })
        .catch((error) => {
          this.hideLoader();
          console.log(error);
          this.showError("Please run the emirates id reader service");
        });
    },
  },
};
</script>

<style></style>
