<template>
  <v-menu
      ref="menu"
      v-model="ageMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :value="getDateString()"
          :label="label"
          :dense="dense"
          :outlined="outlined"
          :solo="solo"
          background-color="#fff"
          v-bind="attrs"
          v-on="on"
          :rules="rules"
          placeholder="DD/MM/YYYY"
          @focus="onFocus"
          @input="handleInput"
          @keydown="handleKeydown"
          @blur="handleDateInput"
          validate-on-blur
          :hide-details="hideDetails"
          :class="className"
      >
        <template v-slot:append>
          <v-btn icon @click="showDatePicker">
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
        :active-picker.sync="activePicker"
        v-model="date"
        :max="new Date().toISOString().substr(0, 10)"
        min="1950-01-01"
        @change="save"
        :hide-details="hideDetails"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: { type: String, default: null },
    rules: { type: Array, default: () => [] },
    outlined: { type: Boolean, default: true },
    dense: { type: Boolean, default: false },
    solo: { type: Boolean, default: false },
    label: { type: String, default: "Date of Birth" },
    hideDetails: { type: String, default: "auto" },
    className: { type: String, default: "" },
  },
  data() {
    return {
      ageMenu: false,
      date: null,
      selectedAgeRange:null,
      activePicker: null,
      maskedInput: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val !== this.date) {
          this.date = val;
        }
      },
    },
    ageMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  mounted() {
    if (this.$store.getters.getCustomerAgeRangeConfiguration.status == false) {
      this.$store.dispatch("LoadCustomerAgeRangeConfiguration");
    }
    if (this.$store.getters.getCustomerAgeRange.status == false) {
      this.$store.dispatch("LoadCustomerAgeRange");
    }
  },
  computed: {
    customerAgeRange() {
      return this.$store.getters.getCustomerAgeRangeConfiguration.data;
    },
    ageRanges() {
      return this.$store.getters.getCustomerAgeRange.data;
    },
    dateString() {
      if (this.date == null) {
        if (this.maskedInput == null) {
          return null;
        }
        return this.maskedInput;
      }
      let age = moment().diff(this.date, "years");
      let dob = moment(this.date, "YYYY-MM-DD").format("Do MMM YYYY");
      return `${dob} (${age})`;
    },
  },
  methods: {
    getDateString() {
      if (this.date == null) {
        if (this.maskedInput == null) {
          return null;
        }
        return this.maskedInput;
      }
      let age = moment().diff(this.date, "years");
      let dob = moment(this.date, "YYYY-MM-DD").format("Do MMM YYYY");
      return `${dob} (${age})`;
    },
    save(date) {
      this.$refs.menu.save(date);
      this.$emit("input", date);
    },
    handleDateInput() {
      // Parse the input and update the Date
      const parsedDate = moment(this.maskedInput, "DD/MM/YYYY", true);
      if (parsedDate.isValid()) {
        this.date = parsedDate.format("YYYY-MM-DD");
        this.save(this.date);
      } else {
        this.date = null;
        this.showError("Please enter valid date in the format dd/mm/yyyy");
      }
    },
    onFocus() {
      // this.showDatePicker();
    },
    handleInput(value) {
      const inputValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      const day = inputValue.slice(0, 2);
      const month = inputValue.slice(2, 4);
      const year = inputValue.slice(4, 8);

      // Apply your specific formatting logic
      let formattedValue = "";
      if (day) formattedValue += day;
      if (month) formattedValue += `/${month}`;
      if (year) formattedValue += `/${year}`;

      if (formattedValue === "") {
        this.date = null;
      }
      this.maskedInput = formattedValue;
    },
    handleKeydown(event) {
      // Prevent non-numeric input
      if (!/^\d+$/.test(event.key) && event.key !== "Backspace") {
        event.preventDefault();
      }
    },
    showDatePicker() {
      this.ageMenu = true;
    },
  },
};
</script>

<style>

.add-on .v-input__append-inner {
  margin-top:5px !important;
}


</style>
