import axios from "../../utils/request";

const getDefaultOrderState = () => {
  return {
    order: {},
    payments: [
      {
        card_type_id: null,
        payment_code: null,
        payment_method_id: null,
        amount: null,
        payment_method: null,
      },
    ],
    creditPayments: [],
    repeatPayments: [],
    multiInvoicePayments: [
      {
        card_type_id: null,
        payment_code: null,
        payment_method_id: null,
        amount: null,
        payment_method: null,
      },
    ],
    items: [],
    customer: {},
    orderBeforeDiscount: {},
    invoice: {},
    invoiceBeforeDiscount: {},
  };
};

const state = getDefaultOrderState();

const getters = {
  getOrderDetails: (state) => state.order,
  getOrderBeforeDiscount: (state) => state.orderBeforeDiscount,
  getOrderItems: (state) => {
    return state.items;
  },
  getOrderPayments: (state) => {
    return state.payments;
  },
  isCreditPayment: (state) => {
    let paymentMethods = state.payments.map((item) => {
      return item.payment_method;
    });

    return paymentMethods.indexOf("Credit") != -1 && paymentMethods.length == 1
      ? true
      : false;
  },
  getCreditOrderPayments: (state) => {
    return state.creditPayments;
  },
  getRepeatPayments: (state) => {
    return state.repeatPayments;
  },
  getOrderCustomer: (state) => {
    return state.customer;
  },
  getOrderPaymentTotal: (state) => {
    return state.payments
      .reduce(
        (total, current) =>
          total + (current.amount ? parseFloat(current.amount) : 0),
        0
      )
      .toFixed(2);
  },
  getCreditPaymentTotal: (state) => {
    return state.creditPayments
      .reduce(
        (total, current) =>
          total + (current.amount ? parseFloat(current.amount) : 0),
        0
      )
      .toFixed(2);
  },
  getRepeatPaymentTotal: (state) => {
    return state.repeatPayments
      .reduce(
        (total, current) =>
          total + (current.amount ? parseFloat(current.amount) : 0),
        0
      )
      .toFixed(2);
  },
  getOrderPaymentCount: (state) => {
    return state.payments.length;
  },
  getCreditPaymentCount: (state) => {
    return state.creditPayments.length;
  },
  getRepeatPaymentCount: (state) => {
    return state.repeatPayments.length;
  },
  getOrderPaymentMethods: (state) => {
    if (state.payments.length) {
      var countryDetails = JSON.parse(localStorage.getItem("country")) || {};
      let payments = state.payments
        .filter((item) => {
          return item.payment_method;
        })
        .map(
          (method) =>
            `${method.payment_method} (${
              countryDetails.currency_code
                ? countryDetails.currency_code
                : "AED"
            } ${method.amount})`
        )
        .join(", ");
      if (payments) return payments;
      return "NA";
    }
    return "NA";
  },

  /** Invoice getters */
  getInvoiceDetails: (state) => state.invoice,
  getInvoiceBeforeDiscount: (state) => state.invoiceBeforeDiscount,
  getInvoicePayments: (state) => {
    return state.payments;
  },
  getInvoicePaymentTotal: (state) => {
    return state.payments.reduce(
        (total, current) =>
          total + (current.amount ? parseFloat(current.amount) : 0),
        0
      )
      .toFixed(2);
  },
  getInvoicePaymentCount: (state) => {
    return state.payments.length;
  },
  getInvoicePaymentMethods: (state) => {
    if (state.payments.length) {
      var countryDetails = JSON.parse(localStorage.getItem("country")) || {};
      let payments = state.payments
        .filter((item) => {
          return item.payment_method;
        })
        .map(
          (method) =>
            `${method.payment_method} (${
              countryDetails.currency_code
                ? countryDetails.currency_code
                : "AED"
            } ${method.amount})`
        )
        .join(", ");
      if (payments) return payments;
      return "NA";
    }
    return "NA";
  },
  getCreditInvoicePayments: (state) => {
    return state.creditPayments;
  },
  getCreditInvoicePaymentTotal: (state) => {
    return state.creditPayments
      .reduce(
        (total, current) =>
          total + (current.amount ? parseFloat(current.amount) : 0),
        0
      )
      .toFixed(2);
  },

  /** Multi Invoice getters */
  getMultiInvoicePayments: (state) => {
    return state.multiInvoicePayments;
  },
  getMultiInvoicePaymentCount: (state) => {
    return state.multiInvoicePayments.length;
  },
  getMultiInvoicePaymentTotal: (state) => {
    return state.multiInvoicePayments.reduce(
        (total, current) =>
            total + (current.amount ? parseFloat(current.amount) : 0),
        0
    )
        .toFixed(2);
  },
};

const actions = {
  loadOrderDetails(context, id) {
    context.commit("resetOrderState");
    var countryDetails = JSON.parse(localStorage.getItem("country")) || {};
    return new Promise((resolve, reject) => {
      axios
        .get(`venues/orders/${id}`)
        .then((response) => {
          if (response && response.status == 200) {
            const data = response.data.data;
            let orderDetails = {};
            orderDetails.id = data.id;
            orderDetails.wallet_redemption = data.wallet_redemption
              ? data.wallet_redemption
              : null;
            orderDetails.facility_booking_details = data.facility_booking_details
              ? data.facility_booking_details
              : null;
            orderDetails.facility_reschedule_details = data.facility_reschedule_details
              ? data.facility_reschedule_details
              : null;
            orderDetails.rental_facility_booking = data.rental_facility_booking
              ? data.rental_facility_booking
              : "";
            orderDetails.order_date = data.order_date;
            orderDetails.paid_at = data.paid_at;
            orderDetails.order_notes = data.order_notes;
            orderDetails.reason = data.refunds[0] ? data.refunds[0].reason : "";
            orderDetails.invoice_generated = data.invoice_generated;
            orderDetails.invoice_item = data.invoice_item;
            orderDetails.order_seq_no = data.order_seq_no;
            orderDetails.invoice_seq_no = data.invoice_seq_no;
            orderDetails.price = data.price;
            orderDetails.total = data.total;
            orderDetails.refund_amount = data.refund_amount;
            orderDetails.created_at = data.created_at;
            orderDetails.tax = data.tax;
            orderDetails.status = data.status.name;
            orderDetails.order_status = data.order_status
              ? data.order_status.name
              : null;
            orderDetails.order_type_id = data.order_type_id;
            orderDetails.type = "payment";
            orderDetails.trn = data.venue.trn;
            orderDetails.cashier = data.cashier
              ? data.cashier.first_name +
                `${data.cashier.last_name ? " " + data.cashier.last_name : ""}`
              : data.create_user
              ? data.create_user.first_name +
                `${
                  data.create_user.last_name
                    ? " " + data.create_user.last_name
                    : ""
                }`
              : "NA";

            // Void user
            if (orderDetails.status == "Void") {
              orderDetails.cashier = data.void_user
                ? data.void_user.first_name +
                  `${
                    data.void_user.last_name
                      ? " " + data.void_user.last_name
                      : ""
                  }`
                : "NA";
            }
            if (
              [
                "Paid",
                "Refund",
                "Cancelled",
                "Hidden",
                "Rescheduled",
                "Complementary",
                "Void",
              ].includes(orderDetails.status)
            ) {
              orderDetails.type = "receipt";
            }
            if (data.credit_order) {
              orderDetails.credit_amount = data.credit_order.credit;
              let creditPayments = [
                {
                  card_type_id: null,
                  payment_code: null,
                  payment_method_id: null,
                  amount: null,
                  payment_method: null,
                },
              ];
              context.commit("setCreditOrderPayments", creditPayments);
            }
            if (data.discount != null) {
              orderDetails.discount = true;
              orderDetails.actual_price = data.discount.actual_price;
              orderDetails.actual_tax = data.discount.actual_tax;
              orderDetails.actual_total = data.discount.actual_total;

              if(data.discount.promotion != null){
                orderDetails.promotion_name = data.discount.promotion.name;
                orderDetails.promotion_code =  data.discount.promotion.promotion_code;
              }else if(data.discount.offer_package){
                orderDetails.promotion_name = "Offer Discount";
                orderDetails.promotion_code =  data.discount.offer_package.package_name;
              }else if(data.discount.member){
                orderDetails.promotion_name = "Member Discount";
                orderDetails.promotion_code =  data.discount.member.card_number;
              }else{
                orderDetails.promotion_name = "Special Discount";
                orderDetails.promotion_name = "";
              }
              if (data.discount.member != null) {
                if (
                  data.discount.wallet_redeem &&
                  data.discount.wallet_redeem.length
                ) {
                  orderDetails.promotion_name = orderDetails.promotion_name
                    ? "Wallet Redeem, " + orderDetails.promotion_name
                    : "Wallet Redeem";
                }
              } else {
                if (
                  data.discount.wallet_redeem &&
                  data.discount.wallet_redeem.length
                ) {
                  orderDetails.promotion_name = orderDetails.promotion_name &&
                    orderDetails.promotion_name !== "Special Discount"
                      ? "Wallet Redeem, " + orderDetails.promotion_name
                      : "Wallet Redeem";
                }
              }
            }
            let items = [];
            data.items.forEach((product) => {
              let pData = {
                is_modified: product.is_modified,
                price: product.price,
                quantity: product.quantity,
                total: product.total,
                tax: product.tax,
                tax_type: product.product.tax_type.name,
                name: product.product.name,
                product_price: product.product.price,
                image: product.product.image,
                product_id: data.product_id,
                id: product.id,
                product_type_id: product.product.product_type_id,
                product_tax: product.product.tax_amount,
              };
              if (product.discount != null) {
                pData.discount = true;
                pData.actual_price = product.discount.actual_price;
                pData.discount_amount =Math.abs(product.discount.actual_price) - Math.abs(product.price);
                pData.actual_tax = product.discount.actual_tax;
                pData.actual_total = product.discount.actual_total;
                pData.promotion_name = product.discount.promotion != null ? product.discount.promotion.name : "Discount";
              }
              items.push(pData);
            });
            let payments = [];
            if (data.payments.length && data.refunds.length === 0) {
              data.payments.forEach((payment) => {
                payments.push({
                  id: payment.id,
                  card_type_id: payment.card_type_id,
                  payment_code: payment.payment_code,
                  payment_method_id: payment.payment_method_id,
                  amount: payment.total,
                  payment_method: payment.payment_method.name,
                  card_number: payment.card_number,
                });
              });
            } else{
              payments.push({
                card_type_id: null,
                payment_code: null,
                payment_method_id: null,
                amount: null,
                payment_method: null,
              });
            }

            let refunds = [];
            if (data.refunds.length) {
              data.refunds.forEach((refund) => {
                refund.payments.forEach((payment) => {
                  payments.push({
                    id: payment.id,
                    card_type_id: payment.card_type_id,
                    payment_code: payment.payment_code,
                    payment_method_id: payment.payment_method_id,
                    amount: payment.total,
                    payment_method: payment.payment_method.name,
                    card_number: payment.card_number,
                  });
                  refunds.push(
                    payment.payment_method.name +
                      "(" +
                      (countryDetails.currency_code
                        ? countryDetails.currency_code
                        : "AED") +
                      " " +
                      Math.abs(payment.amount) +
                      ")"
                  );
                });
              });
            }

            orderDetails.refunds = refunds;

            let customer = {};
            if (data.customer != null) {
              customer.name = `${data.customer.first_name} ${data.customer
                .last_name || ""}`;
              customer.mobile = data.customer.customer_contact.mobile;
              customer.email = data.customer.customer_contact.email;
              customer.wallet = data.customer.wallet;
            }
            orderDetails.credit_settlement = false;
            if (data.settled_order != null) {
              orderDetails.credit_settlement = true;
              orderDetails.received_amount =
                data.settled_order.total_before - data.settled_order.credit;
            }
            if (data.settled_order) {
              orderDetails.credit = data.settled_order.credit;
            } else {
              orderDetails.credit = false;
            }
            orderDetails.source = data.source;
            if (data.isShowOnlineRefund) {
              orderDetails.isShowOnlineRefund = data.isShowOnlineRefund;
            } else {
              orderDetails.isShowOnlineRefund = false;
            }

            context.commit("setOrderCustomer", customer);
            context.commit("setOrderItems", items);
            context.commit("setOrderPayments", payments);
            context.commit("setOrderDetails", orderDetails);
            resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addNewPaymentMethod({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (state.payments[state.payments.length - 1].amount != null) {
        let data = {
          card_type_id: null,
          payment_code: null,
          payment_method_id: null,
          amount: null,
          payment_method: null,
        };
        resolve(data);
        commit("setNewPaymentMethod", data);
      }
      reject({
        status: false,
        data: {
          message: "Please enter price for current payment method",
        },
      });
    });
  },
  addNewCreditPaymentMethod({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (
        state.creditPayments[state.creditPayments.length - 1].amount != null
      ) {
        let data = {
          card_type_id: null,
          payment_code: null,
          payment_method_id: null,
          amount: null,
          payment_method: null,
        };
        resolve(data);
        commit("setNewCreditPaymentMethod", data);
      }
      reject({
        status: false,
        data: {
          message: "Please enter price for current payment method",
        },
      });
    });
  },

  addNewRepeatPaymentMethod({ commit }) {
    return new Promise((resolve) => {
      let data = {
        card_type_id: null,
        payment_code: null,
        payment_method_id: null,
        amount: null,
        payment_method: null,
      };
      resolve(data);
      commit("setNewRepeatPaymentMethod", data);
    });
  },

  resetPaymentMethod({ commit }) {
    return new Promise((resolve) => {
      let data = {
        card_type_id: null,
        payment_code: null,
        payment_method_id: null,
        amount: null,
        payment_method: null,
      };
      resolve(data);
      commit("clearPaymentMethod", []);
      commit("clearRepeatPaymentMethod", []);
      commit("setNewPaymentMethod", data);
      commit("setNewMultiInvoicePaymentMethod", data);
      commit("clearMultiInvoicePaymentMethod", data);
    });
  },

  /** New Methods */
  loadInvoiceDetails(context, data) {
    context.commit("resetInvoiceState");
    return new Promise((resolve, reject) => {

      let url = "";
      if(typeof data === "object") {
        url += `invoice_id=${data.id}&venueId=${data.venue_id}`;
      }else{
        url += `invoice_id=${data}`;
      }
      axios
        .get(`venues/invoices/pre-pay-data?${url}`)
        .then((response) => {
          if (response && response.status == 200) {
            const data = response.data.data;
            let invoiceDetails = {};
            invoiceDetails.id = data.id;
            invoiceDetails.invoice_date = data.invoice_date;
            invoiceDetails.paid_at = data.paid_at;
            invoiceDetails.invoice_notes = data.invoice_notes;
            invoiceDetails.invoice_seq_no = data.invoice_seq_no;
            invoiceDetails.receipt_no = data.receipt_no;
            invoiceDetails.price = data.price;
            invoiceDetails.total = data.total;
            invoiceDetails.tax = data.tax;
            invoiceDetails.status = data.status.name;
            invoiceDetails.order_status = data.invoice_status
              ? data.invoice_status.name
              : null;
            invoiceDetails.type = "payment";
            invoiceDetails.cashier = data.cashier
              ? data.cashier.first_name +
                `${data.cashier.last_name ? " " + data.cashier.last_name : ""}`
              : data.create_user
              ? data.create_user.first_name +
                `${
                  data.create_user.last_name
                    ? " " + data.create_user.last_name
                    : ""
                }`
              : "NA";
            if (data.credit_invoice) {
              invoiceDetails.credit_amount = data.credit_invoice.credit;
              let creditPayments = [
                {
                  card_type_id: null,
                  payment_code: null,
                  payment_method_id: null,
                  amount: null,
                  payment_method: null,
                },
              ];
              context.commit("setCreditInvoicePayments", creditPayments);
            }
            if (data.discount != null) {
              invoiceDetails.discount = true;
              invoiceDetails.actual_price = data.discount.actual_price;
              invoiceDetails.actual_tax = data.discount.actual_tax;
              invoiceDetails.actual_total = data.discount.actual_total;
              invoiceDetails.promotion_name = "Special Discount";
            }
            let payments = [];
            payments.push({
              card_type_id: null,
              payment_code: null,
              payment_method_id: null,
              amount: null,
              payment_method: null,
            });
            // if (invoiceDetails.status != "Cancelled") {
            //   payments.push({
            //     card_type_id: null,
            //     payment_code: null,
            //     payment_method_id: null,
            //     amount: null,
            //     payment_method: null,
            //   });
            // }
            invoiceDetails.credit_settlement = false;
            if (data.settled_invoice != null) {
              invoiceDetails.credit_settlement = true;
              invoiceDetails.received_amount =
                data.settled_invoice.total_before - data.settled_invoice.credit;
            }
            if (data.settled_invoice) {
              invoiceDetails.credit = data.settled_invoice.credit;
            } else {
              invoiceDetails.credit = false;
            }
            invoiceDetails.source = data.source;
            if(data.venue_id){
              invoiceDetails.venue_id = data.venue_id;
            }

            context.commit("setInvoicePayments", payments);
            context.commit("setInvoiceDetails", invoiceDetails);
            resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setCustomer(context, data) {
    return new Promise((resolve) => {
      let customer = {};
      if (data != null) {
        customer.name = data.name || "";
        customer.mobile = data.mobile;
        customer.email = data.email;
        customer.wallet = data.wallet;
      }
      context.commit("setOrderCustomer", customer);
      resolve(customer);
    });
  },

  /** Add new payment method for multi invoice **/
  addNewMultiInvoicePaymentMethod({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (state.multiInvoicePayments[state.multiInvoicePayments.length - 1].amount != null) {
        let data = {
          card_type_id: null,
          payment_code: null,
          payment_method_id: null,
          amount: null,
          payment_method: null,
        };
        resolve(data);
        commit("setNewMultiInvoicePaymentMethod", data);
      }
      reject({
        status: false,
        data: {
          message: "Please enter price for current payment method",
        },
      });
    });
  },
};

const mutations = {
  resetOrderState(state) {
    Object.assign(state, getDefaultOrderState());
  },
  setOrderDetails(state, data) {
    state.order = data;
    state.orderBeforeDiscount = data;
  },
  setOrderPayments(state, data) {
    state.payments = data;
  },
  setCreditOrderPayments(state, data) {
    state.creditPayments = data;
  },
  setOrderItems(state, data) {
    state.items = data;
  },
  setOrderCustomer(state, data) {
    state.customer = data;
  },
  setNewPaymentMethod(state, data) {
    state.payments.push(data);
  },
  setNewCreditPaymentMethod(state, data) {
    state.creditPayments.push(data);
  },
  setNewRepeatPaymentMethod(state, data) {
    state.repeatPayments.push(data);
  },
  setNewMultiInvoicePaymentMethod(state, data) {
    state.multiInvoicePayments.push(data);
  },
  updatePaymentMethod(state, data) {
    state.payments[data.index][data.field] = data.value;
  },
  updateCreditPaymentMethod(state, data) {
    state.creditPayments[data.index][data.field] = data.value;
  },
  updateRepeatPaymentMethod(state, data) {
    state.repeatPayments[data.index][data.field] = data.value;
  },
  updateMultiInvoicePaymentMethod(state, data) {
    state.multiInvoicePayments[data.index][data.field] = data.value;
  },
  removePaymentMethod(state, index) {
    state.payments.splice(index, 1);
  },
  removeCreditPaymentMethod(state, index) {
    state.creditPayments.splice(index, 1);
  },
  removeRepeatPaymentMethod(state, index) {
    state.repeatPayments.splice(index, 1);
  },
  removeMultiInvoicePaymentMethod(state, index) {
    state.multiInvoicePayments.splice(index, 1);
  },
  clearPaymentMethod(state) {
    state.payments = [];
  },
  clearRepeatPaymentMethod(state) {
    state.repeatPayments = [];
  },
  clearMultiInvoicePaymentMethod(state) {
    state.multiInvoicePayments = [{
      card_type_id: null,
      payment_code: null,
      payment_method_id: null,
      amount: null,
      payment_method: null,
    }];
  },
  clearCreditPaymentMethod(state) {
    state.creditPayments = [];
  },
  addSpecialDiscount(state, amount) {
    state.invoice = JSON.parse(JSON.stringify(state.invoiceBeforeDiscount));
    if (amount && amount <= state.invoiceBeforeDiscount.total) {
      let discountAmount = state.invoiceBeforeDiscount.total - amount;
      let discount = (discountAmount / state.invoiceBeforeDiscount.total) * 100;
      let taxPercentage = (state.invoiceBeforeDiscount.tax / 100) * discount;

      state.invoice.discount = true;

      state.invoice.actual_price = state.invoiceBeforeDiscount.price.toFixed(2);
      state.invoice.actual_tax = state.invoiceBeforeDiscount.tax.toFixed(2);
      state.invoice.actual_total = state.invoiceBeforeDiscount.total.toFixed(2);
      // state.invoice.price = (amount - taxPercentage).toFixed(2);
      state.invoice.price = (
        state.invoice.actual_price -
        (state.invoice.actual_price * discount) / 100
      ).toFixed(2);
      state.invoice.tax -= taxPercentage;
      state.invoice.total = parseFloat(parseFloat(amount).toFixed(2));
      state.invoice.promotion_name = "Special Discount";
    }
  },
  addSpecialDiscountPercentage(state, percentage) {
    state.invoice = JSON.parse(JSON.stringify(state.invoiceBeforeDiscount));
    if (percentage != 0) {
      let amount =
        state.invoiceBeforeDiscount.total -
        (state.invoiceBeforeDiscount.total / 100) * percentage;
      let taxPercentage = (state.invoiceBeforeDiscount.tax / 100) * percentage;
      state.invoice.discount = true;
      state.invoice.actual_price = state.invoiceBeforeDiscount.price.toFixed(2);
      state.invoice.actual_tax = state.invoiceBeforeDiscount.tax.toFixed(2);
      state.invoice.actual_total = state.invoiceBeforeDiscount.total.toFixed(2);
      // state.invoice.price = (amount - taxPercentage).toFixed(2);
      state.invoice.price = (
        state.invoice.actual_price -
        (state.invoice.actual_price * percentage) / 100
      ).toFixed(2);
      state.invoice.tax -= taxPercentage;
      state.invoice.total = parseFloat(amount.toFixed(2));
      state.invoice.promotion_name = "Special Discount";
    }
  },

  setInvoiceDetails(state, data) {
    state.invoice = data;
    state.invoiceBeforeDiscount = data;
  },
  resetInvoiceState(state) {
    Object.assign(state, getDefaultOrderState());
  },
  setCreditInvoicePayments(state, data) {
    state.creditPayments = data;
  },
  setInvoicePayments(state, data) {
    state.payments = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
