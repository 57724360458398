// import axios from "../../utils/request";

const getDefaultADConfig = () => {
  return {
    msalConfig: {
      auth: {
        tenant: null,
        clientId: null,
        authority: null,
        redirectUri: null,
        scopes: [],
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
      },
      mode: "redirect",
    },
  };
};

const state = getDefaultADConfig();

const getters = {
  getADConfig: (state) => state.msalConfig,
};

const actions = {
  loadADConfig({ commit }) {
    let obj = {
      auth: {
        tenant: "qportal-test",
        clientId: "635a7b40-82c2-4c93-ae79-49e8b382ac1f",
        authority:
          "https://login.microsoftonline.com/c3d38808-56a4-4475-b0b8-0e44f3802591/",
        redirectUri: "http://localhost:8080/login-sso-success", // It has to be configured on your Azure tenant
        scopes: ["openid", "profile"],
      },
      cache: {
        // cacheLocation: "localStorage",
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
      },
      mode: "redirect",
    };
    commit("setConfigAD", obj);
    // return new Promise((resolve, reject) => {

    //   axios
    //     .get("venues/b2b/get-types")
    //     .then((response) => {
    //       if (response.status == 200 && response.data.status == true) {
    //         commit("setB2bTypes", response.data.data);
    //         resolve(response);
    //       }
    //       reject(response);
    //     })
    //     .catch((err) => {
    //       reject(err);
    //     });
    // });
  },
};

const mutations = {
  setConfigAD(state, obj) {
    state.msalConfig = obj;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
