<template>
  <v-container fluid>
    <div class="d-flex justify-space-between mx-4 align-center">
      <h2>Media Library</h2>
      <div class="d-flex gap-x-2 align-center">
        <v-text-field
            v-model="search"
            background-color="#fff"
            outlined
            dense
            hide-details="auto"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            class="q-text-field shadow-0"
            required
            @input="debouncedInputChange"
        >
          <template v-slot:append>
            <v-menu
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="true"
            >
              <template v-slot:activator="{ on }">
                <div v-on="on" class="d-flex align-center open-product">
                  <v-icon>mdi-tune-variant</v-icon>
                </div>
              </template>
              <v-card>
                <v-card-text>
                  <v-radio-group v-model="searchType" column class="mt-0" hide-details="auto">
                    <v-radio
                        label="Current folder"
                        value="Local"
                    ></v-radio>
                    <v-radio label="All folders" value="Global"></v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>

        <v-menu
            offset-y
            content-class="q-menu"
            v-if="checkWritePermission($modules.mediaLibrary.management.slug)"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color=" darken-1"
                class="white--text blue-color ml-1"
                text
                height="40"
                v-bind="attrs"
                v-on="on"
                v-if="checkWritePermission($modules.mediaLibrary.management.slug)"
            >
              <AddIcon/>
              <span class="ml-1">Add Media</span>
            </v-btn>
          </template>
          <v-list class="rounded-2">
            <v-list-item @click="addMedia = true">
              <v-list-item-title>
                <SvgIcon class="font-medium text-sm gap-x-2" text="File">
                  <template #icon>
                    <FileIcon/>
                  </template>
                </SvgIcon>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="addMedia = true;isFolder = true">
              <v-list-item-title>
                <SvgIcon class="font-medium text-sm gap-x-2" text="Folder">
                  <template #icon>
                    <FolderIcon/>
                  </template>
                </SvgIcon>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="mx-4 w-full mt-4">
      <nav aria-label="breadcrumb" class="breadcrumb-container">
        <ol class="breadcrumb">
          <li v-for="(crumb, index) in breadCrumbs" :key="index" class="breadcrumb-item">
        <span
            @click="handleBreadcrumbClick(crumb)"
            class="breadcrumb-link"
        >
          {{ crumb.name }}
        </span>
            <span v-if="index < breadCrumbs.length - 1"> > </span>
          </li>
        </ol>
      </nav>
    </div>

    <div class="md-card md-theme-default shadow-2 rounded-2">
      <div class="md-card-content">
        <v-simple-table class="table">
          <template v-slot:default>
            <thead>
            <tr class="rounded-lg tr-rounded tr-neon opacity-70">
              <th class="text-left black-text">Name</th>
              <th class="text-left black-text" v-if="search && searchType === 'Global'" style="max-width: 150px">Path
              </th>
              <th class="text-left black-text">
                Type
              </th>
              <th class="text-left black-text">
                <div class="cursor-pointer" @click="sortColumn('date')">
                  Upload Date
                  <v-icon class="ml-2" small>
                    mdi-sort{{ orderBy === "date" ? `-${orderDir}` : '' }}
                  </v-icon>
                </div>
              </th>
              <th class="text-left black-text">
                <div class="cursor-pointer" @click="sortColumn('size')">
                  Size
                  <v-icon class="ml-2" small>
                    mdi-sort{{ orderBy === "size" ? `-${orderDir}` : '' }}
                  </v-icon>
                </div>
              </th>
              <th class="text-left black-text">Uploaded By</th>
              <th class="text-left black-text">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="filteredFolderItems.length < 1">
              <td colspan="6" class="text-center mt-4">
                <h3>No Data found</h3>
              </td>
            </tr>
            <tr class="text-left" v-for="(item,key) in filteredFolderItems" :key="key">
              <td>
                <div class="d-flex align-center gap-x-2">
                  <component v-if="icons[item.extension]" :component="icons[item.extension]"
                             :is="icons[item.extension]"></component>
                  <DefaultIcon v-else></DefaultIcon>
                  <FilePreview
                      :extension="item.extension"
                      :path="item.path"
                      :item="item"
                      @handleCLick="handleCLick"
                  />
                </div>
              </td>
              <td v-if="search && searchType === 'Global'"  >
                  <span class="truncate-tooltip" :title="item.crumbs">{{ item.crumbs }}</span>
              </td>
              <td>
                <span class="text-capitalize">{{ item.extension }}</span>
              </td>
              <td>
                {{ item.uploaded_at |timeStamp }}
              </td>
              <td v-if="item.extension === 'folder'">
                {{ item.child_count }} {{ item.child_count == 1 ? 'Item' : 'Items' }}
              </td>
              <td v-else>
                {{ item.size |formatFileSize }}
              </td>
              <td>
                {{ item.uploader_name }}
              </td>
              <td>
                <v-menu
                    content-class="q-menu"
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="text_capitalize "
                        elevation="0"
                        style="background-color: transparent; min-width: fit-content !important; height: fit-content !important; padding: 2px !important "
                        v-bind="attrs"
                        v-on="on"
                        :ripple="false"
                    >
                      <DotsIcon/>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                        @click="openPreview(item)"
                        v-if="checkReadPermission($modules.mediaLibrary.management.slug) && item.extension !== 'folder'"
                    >
                      <SvgIcon class="font-medium text-sm gap-x-2" text="Preview">
                        <template #icon>
                          <ViewIcon />
                        </template>
                      </SvgIcon>
                    </v-list-item>
                    <v-list-item
                        @click="downloadFile(item)"
                        v-if="checkReadPermission($modules.mediaLibrary.management.slug) && (item.extension !== 'folder' || (item.extension === 'folder' && item.child_count > 0)  )"
                    >
                      <SvgIcon class="font-medium text-sm gap-x-2" text="Download">
                        <template #icon>
                          <DownloadIcon/>
                        </template>
                      </SvgIcon>
                    </v-list-item>
                    <v-list-item
                        @click="copyContent(item.path)"
                        v-if="checkReadPermission($modules.mediaLibrary.management.slug) && item.extension != 'folder'"
                    >
                      <SvgIcon class="font-medium text-sm gap-x-2" text="Share">
                        <template #icon>
                          <ShareIcon/>
                        </template>
                      </SvgIcon>
                    </v-list-item>
                    <v-list-item
                        v-if="checkWritePermission($modules.mediaLibrary.management.slug)"
                        @click="openCopyMoveModal(item,false)"
                    >
                      <SvgIcon class="font-medium text-sm gap-x-2" text="Copy">
                        <template #icon>
                          <CopyIcon/>
                        </template>
                      </SvgIcon>
                    </v-list-item>
                    <v-list-item
                        v-if="checkWritePermission($modules.mediaLibrary.management.slug)"
                        @click="openCopyMoveModal(item,true)"
                    >
                      <SvgIcon class="font-medium text-sm gap-x-2" text="Move">
                        <template #icon>
                          <MoveIcon/>
                        </template>
                      </SvgIcon>
                    </v-list-item>
                    <v-list-item
                        @click="openRenameModal(item)"
                        v-if="checkWritePermission($modules.mediaLibrary.management.slug)"
                    >
                      <SvgIcon class="font-medium text-sm gap-x-2" text="Rename">
                        <template #icon>
                          <EditIcon/>
                        </template>
                      </SvgIcon>
                    </v-list-item>
                    <v-list-item
                        @click="deleteConfirmationItem(item)"
                        v-if="checkDeletePermission($modules.mediaLibrary.management.slug)"
                    >
                      <SvgIcon class="font-medium text-sm gap-x-2 red--text svg-stroke-red" text="Delete">
                        <template #icon>
                          <DeleteIcon/>
                        </template>
                      </SvgIcon>
                    </v-list-item>

                  </v-list>
                </v-menu>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <AddMediaModal
        :AddMediaModal="addMedia"
        :isFolder="isFolder"
        :currentFolder="currentFolder"
        :clearModal="clearModal"
        @closeAddMediaModal="closeAddMediaModal"
        @save="save"
    />

    <MediaRenameModal
        @save="saveRename"
        @closeRenameMedialModal="closeRenameMediaModal"
        :file-name="renameFileName"
        :rename-medial-modal="renameModal"
        :file-id="renameFileId"
    />

    <MediaCopyMoveModal
        :copy-move-file-id="moveFileId"
        :is-move="isMove"
        :copy-move-modal="copyMoveModal"
        @closeCopyMoveModal="closeCopyMoveModal"
        @saveCopyMove="saveCopyMove"
    />

    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="confirmActions"
    ></confirm-model>


    <ViewFileModal
        :extension="item_extension"
        :path="item_path"
        :title="item_name"
        :dialog="viewDialog"
        @closePreview="closePreview"
    />


  </v-container>
</template>
<script>
import AddIcon from "@/assets/images/misc/plus-icon.svg";
import FolderIcon from "@/assets/images/misc/Folder.svg";
import FileIcon from "@/assets/images/misc/File.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import EditIcon from "@/assets/images/tables/edit.svg";
import DeleteIcon from "@/assets/images/misc/delete-icon.svg";
import CsvIcon from "@/assets/images/misc/file-icons/CSV.svg";
import DocIcon from "@/assets/images/misc/file-icons/DOC.svg";
import DocxIcon from "@/assets/images/misc/file-icons/DOCX.svg";
import GifIcon from "@/assets/images/misc/file-icons/GIF.svg";
import JpegIcon from "@/assets/images/misc/file-icons/JPEG.svg";
import JpgIcon from "@/assets/images/misc/file-icons/JPG.svg";
import PdfIcon from "@/assets/images/misc/file-icons/PDF.svg";
import PngIcon from "@/assets/images/misc/file-icons/PNG.svg";
import PptIcon from "@/assets/images/misc/file-icons/PPT.svg";
import TxtIcon from "@/assets/images/misc/file-icons/TXT.svg";
import XlsIcon from "@/assets/images/misc/file-icons/XLS.svg";
import XlsxIcon from "@/assets/images/misc/file-icons/XLSX.svg";
import VideoIcon from "@/assets/images/misc/file-icons/video.svg";
import CopyIcon from "@/assets/images/misc/Copy.svg";
import MoveIcon from "@/assets/images/misc/move.svg";
import ShareIcon from "@/assets/images/misc/share.svg";
import DownloadIcon from "@/assets/images/misc/file-icons/download.svg";
import FolderFileIcon from "@/assets/images/misc/file-icons/Folder.svg";
import DefaultIcon from "@/assets/images/misc/file-icons/Default.svg";
import DotsIcon from "@/assets/images/misc/h-options.svg";
import AddMediaModal from "@/components/MediaLibrary/AddMediaModal.vue";
import MediaRenameModal from "@/components/MediaLibrary/MediaRenameModal.vue";
import MediaCopyMoveModal from "@/components/MediaLibrary/MediaCopyMoveModal.vue";
import FilePreview from "@/components/MediaLibrary/FilePreview.vue";
import ViewFileModal from "@/components/MediaLibrary/ViewFileModal.vue";
import ViewIcon from "@/assets/images/misc/file-icons/eye.svg";

export default {
  name: "MediaLibrary",
  components: {
    ViewIcon,
    ViewFileModal,
    MediaCopyMoveModal,
    MediaRenameModal,
    FilePreview,
    AddMediaModal, DotsIcon, EditIcon, DeleteIcon, SvgIcon, FolderIcon, FileIcon, AddIcon,
    CsvIcon,
    DocIcon,
    DocxIcon,
    GifIcon,
    JpegIcon,
    JpgIcon,
    PdfIcon,
    PngIcon,
    PptIcon,
    TxtIcon,
    FolderFileIcon,
    XlsIcon,
    XlsxIcon,
    DefaultIcon,
    CopyIcon,
    MoveIcon,
    ShareIcon,
    VideoIcon,
    DownloadIcon
  },
  mounted() {
    this.loadFolderData();
  },
  data() {
    return {
      clearModal: false,
      icons: {
        'folder': FolderFileIcon,
        'pdf': PdfIcon,
        'ppt': PptIcon,
        'csv': CsvIcon,
        'xls': XlsIcon,
        'xlsx': XlsxIcon,
        'doc': DocIcon,
        'docx': DocxIcon,
        'jpg': JpgIcon,
        'jpeg': JpegIcon,
        'png': PngIcon,
        'gif': GifIcon,
        'txt': TxtIcon,
        'mp4':VideoIcon,
        'webm':VideoIcon,
        'ogg':VideoIcon,
        'mov':VideoIcon,
      },
      orderBy: null,
      orderDir: 'ascending',
      folderItems: [],
      filteredFolderItems: [],
      search: null,
      searchType: 'Local',
      debounceTimeout: null,
      addMedia: false,
      isFolder: false,
      currentFolder: null,
      renameFileName: null,
      renameFileId: null,
      renameModal: false,
      moveFileId: null,
      isMove: false,
      copyMoveModal: false,
      breadCrumbs: [
        {
          name: 'Library',
          id: null,
        },
      ],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      showPreview:['jpg', 'jpeg', 'png', 'gif','pdf','svg'],
      item_extension:'',
      item_path:null,
      item_name:null,
      viewDialog:false,
    }
  },
  watch:{
    searchType(){
      this.debouncedInputChange();
    },
  },
  methods: {
    openPreview(item){
      this.item_extension = item.extension;
      this.item_path = item.path;
      this.item_name = item.name;
      this.viewDialog = true;
    },
    closePreview(){
      this.item_extension ='';
      this.item_path =null;
      this.item_name =null;
      this.viewDialog = false;
    },
    sortColumn(orderBy) {
      this.orderBy = orderBy;
      if (this.orderDir === 'ascending') {
        this.orderDir = 'descending'
      } else {
        this.orderDir = 'ascending'
      }
      this.sortFolderItems();
    },
    sortFolderItems() {
      this.filteredFolderItems.sort((a, b) => {
        // Determine the field to sort by
        let fieldA, fieldB;

        if (this.orderBy === 'size') {
          // Handle the size sorting
          if (a.extension === 'folder' && b.extension === 'folder') {
            // Both are folders, sort by child_count
            fieldA = a.child_count || 0;
            fieldB = b.child_count || 0;
          } else if (a.extension !== 'folder' && b.extension !== 'folder') {
            // Both are files, sort by size
            fieldA = a.size || 0;
            fieldB = b.size || 0;
          } else {
            // One is a folder, the other is a file; prioritize files
            fieldA = a.extension === 'folder' ? 1 : 0;
            fieldB = b.extension === 'folder' ? 1 : 0;
          }
        } else if (this.orderBy === 'date') {
          // Handle the date sorting
          fieldA = new Date(a.uploaded_at).getTime();
          fieldB = new Date(b.uploaded_at).getTime();
        }

        // Determine the sort direction
        let comparison = 0;
        if (fieldA < fieldB) {
          comparison = -1;
        } else if (fieldA > fieldB) {
          comparison = 1;
        }

        // Apply the sorting direction
        return this.orderDir === 'ascending' ? comparison : -comparison;
      });
    },
    getFileBreadCrumbs(newItems = null) {
      let breadCrumbs = ['Library'];
      // If newItems is provided, append each item to the breadcrumbs array
      if (newItems && Array.isArray(newItems)) {
        newItems.forEach(item => {
          if (item.name && item.id !== undefined) {
            breadCrumbs.push(item.name);
          }
        });
      }
      breadCrumbs.pop();
      return breadCrumbs.join('/ ');
    },
    debouncedInputChange() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.searchHelper(this.search, this.searchType);
      }, 500);
    },
    searchHelper(query, type) {
      if (type === 'Local') {
        this.filteredFolderItems = this.folderItems.filter((item) => {
          return query ? item.name.toLowerCase().includes(this.search.toLowerCase()) : true;
        });
      } else if (type === 'Global') {
        this.searchGlobal(query);
      } else {
        this.showError('Select type of search');
      }
    },
    searchGlobal(query) {
      if(!query){
        this.loadFolderData(false)
        return;
      }
      this.showLoader("Searching");
      this.folderItems = [];
      this.filteredFolderItems = [];
      let url = `venues/media-assets/get/search?params=${query}`;
      this.$http.get(url).then((response) => {
        if (response.status == 200) {
          const data = response.data.data;
          if (data) {
            this.folderItems = data;
          } else {
            this.folderItems = [];
          }
          this.filteredFolderItems = this.folderItems;

          this.filteredFolderItems.map(ele => {
            ele.crumbs = this.getFileBreadCrumbs(ele.bread_crumbs);
            return ele;
          })
        }
        this.generateBreadCrumbs(response.data.breadcrumbs)
      }).catch((error) => {
        this.errorChecker(error);
      }).finally(() => {
        this.hideLoader();
      });
    },
    resetSearch(toggle = true) {
      if(toggle){
        this.searchType = "Local";
      }
      this.search = null;
    },
    downloadFile(item) {
      let url = `venues/media-assets/download/${item.id}`;
      this.$http
          .get(url, { responseType: "blob" })
          .then(response => {
            // Get the content disposition header to extract the filename
            const contentDisposition = response.headers['content-disposition'];
            let fileName = item.name;

            if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
              if (fileNameMatch && fileNameMatch[1]) {
                fileName = fileNameMatch[1];
              }
            }

            // Create a URL for the file blob
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // Cleanup
            link.remove();
            window.URL.revokeObjectURL(url);
          })
          .catch(async error => {
            try {
              const text = await error.response.data.text();
              const json = JSON.parse(text);
              this.showError(json.error || json.message);
            } catch (e) {
              if (error.response && error.response.status === 413) {
                this.showError('The folder or file is too large to download.');
              } else {
                this.showError('Failed to download the folder or file.');
              }
            }
          });
    },

    copyContent(data) {
      const el = document.createElement("textarea");
      el.value = this.s3BucketURL + data;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
          document.getSelection().rangeCount > 0
              ? document.getSelection().getRangeAt(0)
              : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showInfo("Link copied to clipboard", 2000);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    handleBreadcrumbClick(crumb) {
      let id = crumb.id;
      if (this.currentFolder && this.currentFolder.id == id) {
        return;
      }
      if (id) {
        this.currentFolder.id = id;
        this.loadFolderData();
      } else {
        this.currentFolder = null;
        this.loadFolderData();
      }
    },
    handleCLick(item) {
      if (item.extension === 'folder') {
        this.currentFolder = item;
        this.loadFolderData();
      } else {
        this.openFile(item.path);
      }
    },
    closeAddMediaModal() {
      this.isFolder = false;
      this.addMedia = false;
      this.clearModal = !this.clearModal;
    },
    closeRenameMediaModal() {
      this.renameFileName = null;
      this.renameFileId = null;
      this.renameModal = false;
    },
    openRenameModal(item) {
      this.renameFileName = item.name;
      this.renameFileId = item.id;
      this.renameModal = true;
    },
    openCopyMoveModal(item, move = false) {
      this.moveFileId = item.id;
      this.isMove = move;
      this.copyMoveModal = true;
    },
    closeCopyMoveModal() {
      this.moveFileId = null;
      this.isMove = null;
      this.copyMoveModal = false;
    },
    saveCopyMove(data) {
      this.showLoader("Loading");
      this.$http.post(`venues/media-assets/set/copy-move`, data)
          .then((response) => {
            if (response.status == 200) {
              this.loadFolderData();
              this.closeCopyMoveModal();
            }
          }).catch((error) => {
        this.errorChecker(error);
      }).finally(() => {
        this.hideLoader();
      });
    },
    saveRename(data) {
      this.showLoader("Loading");
      this.$http.post(`venues/media-assets/set/rename`, data)
          .then((response) => {
            if (response.status == 200) {
              this.loadFolderData();
              this.closeRenameMediaModal();
            }
          }).catch((error) => {
        this.errorChecker(error);
      }).finally(() => {
        this.hideLoader();
      });
    },
    save(data) {
      this.showLoader("Loading");
      let url = `venues/media-assets/set`;
      if (this.currentFolder) {
        url += '/' + this.currentFolder.id;
      }

      let formData = new FormData();

      if (data.file) {
        formData.append("upload_file", data.file);
      }
      if (!this.isFolder && !data.file) {
        this.showError('Please upload a file first');
        this.hideLoader();
        return;
      }

      formData.append("name", data.name);
      formData.append("type", this.isFolder ? 'folder' : 'file');
      formData.append("folder_id", this.currentFolder ? this.currentFolder.id : null);

      this.$http({
        method: "post",
        data: formData,
        url: url,
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      }).then((response) => {
        if (response.status == 200) {
          this.loadFolderData();
          this.closeAddMediaModal();
        }
      }).catch((error) => {
        this.errorChecker(error);
      }).finally(() => {
        this.hideLoader();
        this.resetSearch();
      });
    },
    loadFolderData(toggle) {
      this.showLoader("Loading");
      this.folderItems = [];
      this.filteredFolderItems = [];
      let url = `venues/media-assets/get`;
      if (this.currentFolder) {
        url += '/' + this.currentFolder.id;
      }
      this.$http.get(url).then((response) => {
        if (response.status == 200) {
          const data = response.data.data;
          if (data) {
            this.folderItems = data;
          } else {
            this.folderItems = [];
          }
          this.filteredFolderItems = this.folderItems;
        }
        this.generateBreadCrumbs(response.data.breadcrumbs)
      }).catch((error) => {
        this.errorChecker(error);
      }).finally(() => {
        this.hideLoader();
        this.resetSearch(toggle);
      });
    },
    deleteConfirmationItem(item) {
      let desc = item.extension == 'folder' ? "By clicking <b>Yes</b> you can confirm the operation. Any items in this folder will also be deleted.  Do you need to continue your action ?" : "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?";
      this.confirmModel = {
        id: item.id,
        title: `Do you want to delete this item?`,
        description:
        desc,
        type: 'delete',
      };
    },
    confirmActions(data) {
      if (data.type == "delete") {
        this.deleteItem(data.id);
      }
      this.confirmModel.id = null;
    },
    deleteItem(item) {
      this.showLoader('Deleting, please wait');
      let url = `venues/media-assets/delete/${item}`;
      this.$http.get(url).then((response) => {
        if (response.status == 200) {
          this.loadFolderData();
        }
      }).catch((error) => {
        this.errorChecker(error);
      }).finally(() => {
        this.hideLoader();
        this.resetSearch();
      });
    },
    generateBreadCrumbs(newItems = null) {
      this.breadCrumbs = [
        {
          name: 'Library',
          id: null,
        },
      ];
      // If newItems is provided, append each item to the breadcrumbs array
      if (newItems && Array.isArray(newItems)) {
        newItems.forEach(item => {
          if (item.name && item.id !== undefined) {
            this.breadCrumbs.push({
              name: item.name,
              id: item.id,
            });
          }
        });
      }
    },
  }
}
</script>
<style scoped>
.breadcrumb-container {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.breadcrumb {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.breadcrumb-link {
  cursor: pointer;
  color: black;
}

.breadcrumb-link:hover {
  text-decoration: underline;
}

.breadcrumb-item span {
  margin-right: 5px;
}

.truncate-tooltip {
  max-width: 150px; /* Adjust based on your table column width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.truncate-tooltip:hover::before {
  content: attr(title);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: normal;
  z-index: 10;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.breadcrumb-link {
  word-wrap: break-word; /* Ensures words break into the next line if too long */
  white-space: normal; /* Allows text to wrap to the next line */
  display: inline-block;
}

</style>