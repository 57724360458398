<template>
  <v-container fluid>
    <v-row>
      <!-- Facilities List -->
      <v-col cols="3" md="3" class="pr-0">
        <v-list class="fixed-height py-0  bordered rounded-t-2">
          <v-list-item class="list-item list-heading">
            <v-list-item-content>
              <v-list-item-title>Facilities List</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-for="(facility, index) in facilities"
              :key="index"
              @click="selectFacility(facility)"
              :class="{ 'blue--text': selectedFacility.id === facility.id }"
              class="list-item border-bottom"
          >
            <v-list-item-content>
              <v-list-item-title>{{ facility.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <!-- Packages Table -->
      <v-col cols="9" md="9">
        <v-card class="card-background-silver rounded-2 shadow-0">
          <v-card-title>
            {{ selectedFacility.name }}
          </v-card-title>

          <v-card-text class="rounded-2 fixed-height">
            <v-simple-table class="table p-4 table-bordered overflow-y-auto"
                            style="background-color: #FFFFFF; border-radius: 12px ;">
              <thead>
              <tr class="">
                <td>Product Name</td>
                <td>Duration (Minutes)</td>
                <td>Price</td>
                <td>Tax</td>
                <td>Price (Inc Vat)</td>
                <td>Actions</td>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(product,
                    tp) in selectedFacility.products"
                  :key="tp"
              >
                <td>{{ product.name }}</td>
                <td>{{ product.duration }}</td>
                <td>{{ product.price  | toCurrency }}</td>
                <td>{{ product.tax_amount  | toCurrency }}</td>
                <td>{{ product.total_price  | toCurrency }}</td>
                <td class="text-center">
                  <v-btn
                      icon
                      @click="editProduct(product)"
                  >
                    <EditIcon height="16" viewBox="0 0 20 20" width="16"/>
                  </v-btn>
                </td>

              </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditIcon from "@/assets/images/tables/edit.svg";

export default {
  components: {EditIcon},
  mounted() {
    this.loadFacilities();
  },
  props:{
    refresh:{ type:Boolean, default: false},
  },
  watch: {
    refresh(){
      this.loadFacilities();
    }
  },
  data() {
    return {
      facilities: [],
      selectedFacility: {
        name: '',
        products: [],
      },

    };
  },
  methods: {
    selectFacility(facility) {
      this.selectedFacility = facility;
      this.loadFacilityProducts();
    },
    loadFacilities() {
      this.showLoader('Loading Facilities');
      this.$http.get(`venues/master-pricing/get/facilities`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.facilities = response.data.data;
              if (this.facilities.length > 0) {
                this.selectFacility(this.facilities[0]);
              }
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
    loadFacilityProducts() {
      if (!this.selectedFacility.id) {
        return;
      }
      this.selectedFacility.products = [];
      this.showLoader("Loading products");
      this.$http.get(`venues/master-pricing/get/products/facilities/${this.selectedFacility.id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.selectedFacility.products = response.data.data;
              console.log(this.selectedFacility)
              this.$forceUpdate()
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
    editProduct(product) {
      this.$emit('editProduct', product);
    },
  },
}
</script>

<style scoped>
.list-item {
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  text-align: center;
}

.list-item.blue--text {
  background-color: #112A46;
}

.card-background-silver {
  background-color: #F0F5F9;
}

.list-item.v-list-item.v-list-item--link.theme--light.blue--text {
  color: white !important;
}

.rounded-t-2 {
  border-radius: 0.5rem 0.5rem 0 0 !important;
}

.bordered {
  border: 1px solid #EAEAEA !important;
  overflow: hidden;
}

.list-heading {
  background: #E9F1F6 !important;
  color: black;
  font-weight: bold;
}

.fixed-height {
  max-height: calc(100vh - 200px);
  overflow: auto;
}

</style>