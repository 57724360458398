import router from "../router";
import store from "../store";
import modules from "./moduleStructure";

router.beforeEach((to, from, next) => {
  if (to.meta.item == "marketing") {
    to.meta.module =
      to.params.type != null ?
      modules.marketing[to.params.type] :
      to.meta.module;
  }
  if (
    store.getters.isPermissionsLoaded == false &&
    to.name != "Login" &&
    to.name != "Root" &&
    to.name != "LoginAD"
  ) {
    store.dispatch("loadPermissions").then((res) => {
      if (res.status == 200) {
        if (to.meta.module.slug != null) {
          if (store.getters.checkReadPermission(to.meta.module.slug)) {
            next();
          } else {
            next({
              name: "Forbidden",
            });
          }
        } else {
          next();
        }
      }
    });
  } else {
    if (to.meta.module.slug != null) {
      if (store.getters.checkReadPermission(to.meta.module.slug)) {
        next();
      } else {
        next({
          name: "Forbidden",
        });
      }
    } else {
      next();
    }
  }
});