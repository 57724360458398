import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);
import EditBtnIcon from '@/assets/images/misc/pencil-icon.svg';

export default new Vuetify({

  theme: {
    themes: {
      light: {
        primary: "#112A46",
        secondary: "#4FAEAF",
        accent: "#204165",
        error: "#A52A2A",
        form_bg: "#edf9ff",
        stage_bg: "#edf9ff",
        top_bar: "#062b48",
        background: "#F8FAFB",
        // background: "#F8FAFB",
        bg_form_color: "#f2f2f2",
        menu_icon: "#066a8c",
        menu_bg: "#f2f2f2",
      },
      dark: {
        primary: "#ffffff",
        form_bg: "#333333",
        background: "#212121",
        stage_bg: "#333333",
        top_bar: "#062b48",
        menu_icon: "#ffffff",
        menu_bg: "#000000",

      },
    },

  },
  icons: {
    iconfont: 'mdi',
    values: {
      dropdown: 'mdi-chevron-down'
    },
    customIcon: {
      pencilIcon: EditBtnIcon ,
    },
  }
});