import axios from "../../utils/request";

const getDefaultGeneralState = () => {
  return {
    countries: {
      status: false,
      data: [],
    },
    installmentPlans: {
      status: false,
      data: [],
    },
    tags: {
      status: false,
      data: [],
    },
    tagCategories: {
      status: false,
      data: [],
    },
    weekdays: {
      status: false,
      data: [],
    },
    memberships: {
      status: false,
      data: [],
    },
    events: {
      status: false,
      data: [],
    },
    workshops: {
      status: false,
      data: [],
    },
    productTypes: {
      status: false,
      data: [],
    },
    documentTypes: {
      status: false,
      data: [],
    },
    trainers: {
      status: false,
      data: [],
    },
    categories: {
      status: false,
      data: [],
    },
    idProofTypes: {
      status: false,
      data: [],
    },
    promotions: {
      status: false,
      data: [],
    },
    taxTypes: {
      status: false,
      data: [],
    },
    salesTeams: {
      status: false,
      data: [],
    },
    actionsList: {
      status: false,
      data: [],
    },
    customerAgeRange: {
      status: false,
      data: [],
    },
    customerAgeRangeConfiguration: {
      status: false,
      data: [],
    },
  };
};

const state = getDefaultGeneralState();

const getters = {
  getDocumentTypes: (state) => state.documentTypes,
  getCountries: (state) => state.countries,
  getInstallmentPlans: (state) => state.installmentPlans,
  getTags: (state) => state.tags,
  getTagCategories: (state) => state.tagCategories,
  getWeekdays: (state) => state.weekdays,
  getWeekdaysFromBitvalue: (state) => (bitArray) => {
    return state.weekdays.data
      .filter((item) => bitArray.includes(item.bit_value))
      .map((item) => item.name)
      .join(",");
  },
  getMemberships: (state) => state.memberships,
  getEvents: (state) => state.events,
  getProductTypes: (state) => state.productTypes,
  getProductTypesCustom: (state) => (exceptions) => {
    if (exceptions == null) {
      return state.productTypes.data;
    }
    return state.productTypes.data.filter(
      (item) => !exceptions.includes(item.name)
    );
  },
  getTrainers: (state) => state.trainers,
  getCategories: (state) => state.categories,
  getIdProofTypes: (state) => state.idProofTypes,
  getPromotions: (state) => state.promotions,
  getTaxTypes: (state) => state.taxTypes,
  getSalesTeams: (state) => state.salesTeams,
  getActionsList: (state) => state.actionsList,
  getCustomerAgeRange: (state) => state.customerAgeRange,
  getCustomerAgeRangeConfiguration: (state) => state.customerAgeRangeConfiguration,
};

const actions = {
  loadCountries({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("countries")
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            commit("setCountries", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadInstallmentPlans({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("installment-plans")
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            commit("setInstallmentPlans", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTags({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/customers/all-tags")
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            commit("setTags", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTagCategories({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/customers/tags/categories")
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            commit("setTagCategories", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadWeekdays({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/general/weekdays")
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            commit("setWeekdays", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadMemberships({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/memberships/short")
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            commit("setMemberships", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadEvents({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/events/short")
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            commit("setEvents", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadProductTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/products/types")
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            commit("setProductTypes", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadDocumentTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("general-types/document-type")
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            commit("setDocumentTypes", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadIDProofTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("general-types/id-proof-type")
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            commit("setIDProofTypes", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadPromotions({ commit }, params) {
    return new Promise((resolve, reject) => {
      let url = "";
      if (params) {
        if (params.date) {
          url += "&date=" + params.date;
        }
        if (params.venue_service_id) {
          url += "&venue_service_id=" + params.venue_service_id;
        }
        if (params.trainer_id) {
          url += "&trainer_id=" + params.trainer_id;
        }
        if (params.product_type) {
          url += "&product_type=" + params.product_type;
        }
        if (params.membership_id) {
          url += "&membership_id=" + params.membership_id;
        }
        if (params.product_id) {
          url += "&product_id=" + params.product_id;
        }
      }

      axios
        .get("venues/promotions/short?q=1" + url)
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            commit("setPromotions", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTaxTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/general/taxes")
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            commit("setTaxTypes", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadSalesTeams(context, type) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/sales-team/short?product_type=" + type)
        .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
            context.commit("setSalesTeams", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadActionsList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/leads/action-list")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            commit("setActionsList", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  LoadCustomerAgeRangeConfiguration({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/customers/get-configuration")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            commit("SetCustomerAgeRangeConfiguration", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  LoadCustomerAgeRange({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/general/age-groups")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            commit("SetCustomerAgeRange", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  resetGeneralState(state) {
    Object.assign(state, getDefaultGeneralState());
  },
  setCountries: (state, nationalities) => {
    state.countries.status = true;
    state.countries.data = nationalities;
  },
  setInstallmentPlans: (state, data) => {
    state.installmentPlans.status = true;
    state.installmentPlans.data = data;
  },
  setTags: (state, data) => {
    state.tags.status = true;
    state.tags.data = data;
  },
  setTagCategories: (state, data) => {
    state.tagCategories.status = true;
    state.tagCategories.data = data;
  },
  setWeekdays: (state, weekdays) => {
    state.weekdays.status = true;
    state.weekdays.data = weekdays;
  },
  setMemberships(state, data) {
    state.memberships.status = true;
    state.memberships.data = data;
  },
  setWorkshops(state, data) {
    state.workshops.status = true;
    state.workshops.data = data;
  },
  setEvents(state, data) {
    state.events.status = true;
    state.events.data = data;
  },
  setProductTypes: (state, stages) => {
    state.productTypes.status = true;
    state.productTypes.data = stages;
  },
  setDocumentTypes(state, data) {
    state.documentTypes.status = true;
    state.documentTypes.data = data;
  },
  setTrainers(state, data) {
    state.trainers.status = true;
    state.trainers.data = data;
  },
  setIDProofTypes(state, data) {
    state.idProofTypes.status = true;
    state.idProofTypes.data = data;
  },
  setPromotions: (state, stages) => {
    state.promotions.status = true;
    state.promotions.data = stages;
  },
  setTaxTypes: (state, data) => {
    state.taxTypes.status = true;
    state.taxTypes.data = data;
  },
  setSalesTeams: (state, data) => {
    state.salesTeams.status = true;
    state.salesTeams.data = data;
  },
  SetCustomerAgeRangeConfiguration: (state, data) => {
    state.customerAgeRangeConfiguration.status = true;
    state.customerAgeRangeConfiguration.data = data && data.enable_age_range ? data.enable_age_range : 0;
  },
  SetCustomerAgeRange: (state, data) => {
    state.customerAgeRange.status = true;
    state.customerAgeRange.data = data;
  },
  setActionsList: (state, data) => {
    state.actionsList.status = true;
    state.actionsList.data = data;
  },
  };

export default {
  state,
  getters,
  actions,
  mutations,
};
