<template>
  <v-row no-gutters wrap>
    <v-col md="12">
      <v-autocomplete
        :value="value"
        :items="entries"
        :loading="isSearchLoading"
        :search-input.sync="search"
        item-text="name"
        item-value="name"
        :label="label"
        placeholder="Name.."
        :rules="[(v) => !!v || 'Name is required']"
        @change="changeCustomerData"
        @blur="entryShift"
        @update:search-input="debouncedInputChange"
        auto-select-first
        return-object
        outlined
        required
        :clearable="clearable"
        autocomplete="cc-mob"
        :background-color="backgroundColor"
        :class="className"
        :dense="dense"
        ref="nameField"
        :hide-details="hideDetails"

      >
        <template v-slot:item="{ item }">
          <v-list-item-avatar
            v-if="item.profile_image"
            rounded
            color="teal"
            class="text-h5 font-weight-light white--text"
          >
            <view-image
              :image="item.profile_image"
              :contain="false"
            ></view-image>
          </v-list-item-avatar>
          <v-list-item-avatar
            v-else-if="item.name"
            rounded
            color="teal"
            class="text-h5 font-weight-light white--text"
          >
            {{ item.name.charAt(0) }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.mobile }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: { type: Object },
    selected: { type: String },
    mobile: { type: String, default: null },
    backgroundColor: { type: String, default: '#fff' },
    label: { type: String, default: 'Name*' },
    email: { type: String, default: null },
    className: { type: String, default: null },
    clearable: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    hideDetails: { type: String, default: '' },
    venueId:{ type: Number, default: null },
  },
  data() {
    return {
      isSearchLoading: false,
      customerDoesNotExist: false,
      entries: [],
      search: null,
      debounceTimeout: null,
    };
  },
  watch: {
    selected: {
      immediate: true,
      handler(val) {
        if (
          this.value != null &&
          this.value.name != null &&
          this.value.name == val
        ) {
          let data = { name: val };
          this.entries = [data];
          this.$emit("input", data);
          return;
        }
        if (val == "" || val == null) {
          this.$emit("input", { name: null });
          setTimeout(() => {
            this.$refs.nameField.reset();
          });
          this.entries = [];
          return;
        }

        let data = { name: val };
        this.entries = [data];
        this.$emit("input", data);
      },
    },
    // search(val) {
    //   clearTimeout(this.debounceTimeout);
    //   this.debounceTimeout = setTimeout(() => {
    //     this.searchHelper(val);
    //   }, 300); // Adjust the debounce delay as needed
    //   // setTimeout(this.searchHelper(val), 500);
    // },
  },
  methods: {
    debouncedInputChange() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.searchHelper(this.search);
      }, 300);
    },
    searchHelper(val) {
      if (this.isSearchLoading) return;
      if (!val) {
        this.$emit("updateCustomer", {});
        return;
      }

      if (!val || (this.value != null && this.value.name == val)) {
        // this.customerDoesNotExist = false;
        return;
      }
      //TODO: Customer exist check
      // if (this.customerDoesNotExist) {
      //   this.entries = [{ mobile: null, name: val, email: null }];
      //   return;
      // }
      if (val && val.length > 2) this.searchCustomer(val);
    },
    searchCustomer(name) {
      this.isSearchLoading = true;
      let venue = "";
      if(this.venueId){
        venue += "&venueId="+this.venueId; 
      }
      this.$http
        .get(`venues/customers/vc-search?name=${name}${venue}`)
        .then((response) => {
          if (response.status == 200) {
            this.isSearchLoading = false;
            let data = response.data.data;
            if (data.length == 0) {
              // this.customerDoesNotExist = true;
              data.unshift({
                mobile: this.mobile,
                name: name,
                email: this.email,
              });
            }
            this.entries = data;
            this.entries.unshift({
              mobile: this.mobile,
              name: name,
              email: this.email,
            });
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    entryShift() {
      if (this.entries.length == 1 && this.value.first_name) {
        this.changeCustomerData(this.entries[0]);
      }
    },
    changeCustomerData(data) {
      console.log(data);
      if (!data) {
        return;
      }
      let customer = {};

      if (data && data.id) {
        customer.mobile = data.mobile;
        customer.name = `${data.first_name}${
          data.last_name ? " " + data.last_name : ""
        }`;
        customer.email = data.email;
        customer.country_id = data.country_id;
        customer.gender = data.gender;
        customer.dob = data.dob;
        customer.age_group = data.age_group;
        customer.last_name = data.last_name ? data.last_name.trim() : null;
        customer.first_name = data.first_name.trim();
        customer.image_path = data.profile_image;
        customer.customer_id = data.id;
        customer.opt_marketing = data.opt_marketing;
        customer.customer_documents = data.customer_documents;
        if (data.venue_customer_tags && data.venue_customer_tags.length) {
          const tags = [];
          data.venue_customer_tags.map((cvt) => {
            tags.push({ id: cvt.tag.id, name: cvt.tag.name });
          });
          customer.customer_tag = tags;
        }
      } else {
        let index = data.name.indexOf(" ");
        customer.first_name = data.name.trim();
        if (index != -1) {
          customer.name = data.name;
          customer.first_name = data.name.substr(0, index).trim();
          customer.last_name = data.name.substr(index + 1).trim();
        } else {
          customer.last_name = "";
        }
        if (data.mobile != null) {
          customer.mobile = data.mobile;
        }
        if (data.email != null) {
          customer.email = data.email;
        }
      }
      if (customer.first_name == "") {
        customer.first_name = customer.last_name;
        customer.last_name = "";
      }
      this.$emit("updateCustomer", customer);
      this.$emit("input", data);
    },
  },
};
</script>

<style></style>
