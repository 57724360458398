<template>
  <app-layout></app-layout>
</template>

<script>
import AppLayout from "./layout/Index";
export default {
  name: "App",
  components: {
    AppLayout,
  },
  data: () => ({
    //
  }),
  mounted() {
    // bypass capitalization of buttons, a and tabs. css is not working
    setTimeout(()=>{
      const elements = document.querySelectorAll('button,a,.v-tab');
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        element.style.textTransform = 'unset'
      }
    },2000)
  }
};
</script>
<style lang="scss">
@import "./assets/style/global.scss";
</style>
