const images = {
  ground: require('@/assets/images/default_images/ground_default.jpg'),
  default: require('@/assets/images/default_images/image_default.jpg'),
  user: require('@/assets/images/default_images/profile_blank.png'),
  map: require('@/assets/images/default_images/map_default.jpg'),
  pdf: require('@/assets/images/default_images/pdf.png'),
  stage: require('@/assets/images/default_images/stage_default.jpg'),
  membership: require('@/assets/images/default_images/membership.png'),
  promotion: require('@/assets/images/default_images/promotion.jpg'),
  event: require('@/assets/images/default_images/event_default.jpg'),
  workshop: require('@/assets/images/default_images/workshop_default.jpg'),
  login: require('@/assets/images/default_images/login_default.png'),
  empty_cart: require('@/assets/images/default_images/empty_cart.png'),
};

export default images;