import axios from "../../utils/request";

const getEventConfigurations = () => {
    return {
        bookingFields: {
            status:false,
            data:[]
        },
    };
};

const state = getEventConfigurations();


const getters = {
    getFieldConfigurations: (state) => state.bookingFields,
}

const actions = {
    loadEventFieldConfigurations({commit}) {
        return new Promise((resolve, reject) => {
            axios
                .get("venues/events/configuration")
                .then((response) => {
                    if (response && response.status == 200 && response.data.status == true) {
                        commit("setEventFields", response.data.data.field_configurations);
                        resolve(response);
                    }
                    reject(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};


const mutations = {
    setEventFields(state, data) {
        state.bookingFields.status = true;
        state.bookingFields.data = data;
    },
}
export default {
    state,
    getters,
    actions,
    mutations,
};
