import axios from "../../utils/request";

const getDefaultB2bTypeState = () => {
  return {
    b2b_types: {
      status: false,
      data: [],
    },
    b2b_cities: {
      status: false,
      data: [],
    },
  };
};

const state = getDefaultB2bTypeState();

const getters = {
  getB2bTypes: (state) => state.b2b_types,
  getB2bCities: (state) => state.b2b_cities,
};

const actions = {
  loadB2bTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/b2b/get-types")
        .then((response) => {
          if (response && response.status == 200 && response.data.status == true) {
            commit("setB2bTypes", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadB2bCities({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/b2b/get-cities")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            commit("setB2bCities", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  resetB2bTypeState(state) {
    Object.assign(state, getDefaultB2bTypeState());
  },
  setB2bTypes(state, b2bTypes) {
    state.b2b_types.status = true;
    state.b2b_types.data = b2bTypes;
  },
  setB2bCities(state, b2bCities) {
    state.b2b_cities.status = true;
    console.log(b2bCities);
    state.b2b_cities.data = b2bCities;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
