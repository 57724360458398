import axios from "axios";
import router from "../router";
import store from "../store";

const $axios = axios.create({
  baseURL:
    process.env.VUE_APP_API_BASE_URL !== undefined
      ? process.env.VUE_APP_API_BASE_URL
      : "https://venueapi.krews.com/api/v1/",
    // headers: {
    //   Accept: 'application/json, application/xml, text/html, multipart/form-data; boundary=${form._boundary}' // Multiple media types
    // }
});

$axios.CancelToken = axios.CancelToken;
$axios.isCancel = axios.isCancel;

$axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = token;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

$axios.interceptors.response.use(
  (response) => {
    // TODO: X-Token
    // console.log(response.headers);
    if (response.headers["X-Token"]) {
      console.log(response.headers["X-Token"]);
      // const token = "Bearer " + response.headers['X-Token'];
      // localStorage.setItem("token", token);
    }
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      if (
        router.currentRoute.name != "Root" &&
        router.currentRoute.name != "LoginAD" &&
        router.currentRoute.name != "Login"
      ) {
        router.push("/", () => {});
        store.dispatch("showOverlayLoader", {
          text: "",
          show: false,
        });
        store.dispatch("showSnack", {
          text: 'Please login again',
          type: "error",
          timeout: 10000,
        });
      }
    } else if (400 === error.response.status) {
      if (
        router.currentRoute.name != "Root" &&
        router.currentRoute.name != "LoginAD" &&
        router.currentRoute.name != "Login"
      ) {
        // router.push("/", () => {});
        // store.dispatch("showOverlayLoader", {
        //   text: "",
        //   show: false,
        // });
      }
    } else if (403 === error.response.status) {
      if (
        router.currentRoute.name != "Root" &&
        router.currentRoute.name != "LoginAD" &&
        router.currentRoute.name != "Login"
      ) {
        router.push("/forbidden", () => {});
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default $axios;
