<template>
  <v-row no-gutters wrap>
    <v-col :md="dialCodeGridSize">
      <v-select
        v-model="country"
        :items="countries"
        item-text="name"
        item-value="iso_2"
        :label="showLabel?'Country ':''"
        return-object
        outlined
        :menu-props="{ bottom: true, offsetY: true }"
        :background-color="backgroundColor"
        :class=className1
        :dense="dense"
        :hide-details="hideDetails"
      >
        <template v-slot:selection="{ attr, on, item }"  >
          <v-avatar v-bind="attr" rounded size="18" color="white" v-on="on" v-if="showFlag">
            <view-image
              :image="`flags/${item.iso_2}.png`"
              :contain="false"
            ></view-image>
          </v-avatar>
          <span class="ml-2 text-bold" v-if="showFlag">{{ item.iso_2 }}</span>
          <span class="ml-2 text-bold mobile_country_code" v-else>+{{ item.code }}</span>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-avatar
            rounded
            color="white"
            class="text-h5 font-weight-light white--text"
          >
            <view-image
              :image="`flags/${item.iso_2}.png`"
              :contain="false"
            ></view-image>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              >{{ item.iso_2 }} -+{{ item.code }}</v-list-item-title
            >
            <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>
    </v-col>
    <v-col :md="12-dialCodeGridSize">
      <v-autocomplete
        :value="value"
        :items="entries"
        :loading="isSearchLoading"
        :search-input.sync="search"
        @update:search-input="debouncedInputChange"
        item-text="mobile"
        item-value="id"
        :label="showLabel?'Mobile ':''"
        placeholder="Mobile.."
        @change="changeCustomerData"
        @blur="entryShift"
        auto-select-first
        return-object
        outlined
        required
        autocomplete="cc-mob"
        :background-color="backgroundColor"
        :rules="mobileRules()"
        :class=className2
        ref="mobileField"
        @select="debouncedInputChange"
        :dense="dense"
        :hide-details="hideDetails"


      >
        <template v-slot:item="{ item }">
          <v-list-item-avatar
            v-if="item.profile_image"
            rounded
            color="teal"
            class="text-h5 font-weight-light white--text"
          >
            <view-image
              :image="item.profile_image"
              :contain="false"
            ></view-image>
          </v-list-item-avatar>
          <v-list-item-avatar
            v-else-if="item.first_name"
            rounded
            color="teal"
            class="text-h5 font-weight-light white--text"
          >
            {{ item.first_name.charAt(0) }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.mobile }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ item.first_name }} {{ item.last_name }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import {
  parsePhoneNumberFromString,
  parsePhoneNumber,
} from "libphonenumber-js";
export default {
  inheritAttrs: false,
  props: {
    value: { type: Object },
    selected: { type: String },
    isUserCheck: { type: Boolean, default: false },
    className1: { type: String  , default: 'text_field1'},
    className2: { type: String  , default: 'text_field2'},
    backgroundColor: { type: String  , default: '#fff'},
    dense: { type: Boolean, default: false },
    showFlag: { type: Boolean, default: true },
    showLabel: { type: Boolean, default: true },
    hideDetails: { type: String, default: '' },
    dialCodeGridSize: { type: Number, default: 5 },
    venueId:{ type: Number, default: null },
  },
  data() {
    return {
      isSearchLoading: false,
      customerDoesNotExist: false,
      entries: [],
      search: null,
      country: {},
      debounceTimeout: null,
    };
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
  },
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries").then((response) => {
        if (response) {
          this.country = this.countries.find(
            (item) => item.iso_2 == this.$store.getters.countryIso_2
          );
        }
      });
    } else {
      this.country = this.countries.find(
        (item) => item.iso_2 == this.$store.getters.countryIso_2
      );
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler(val) {
        if (val == "" || val == null) {
          setTimeout(() => {
            this.$refs.mobileField.reset();
          });
          this.$emit("input", { mobile: null });
          this.entries = [];
          return;
        }
        if (
          this.value != null &&
          this.value.mobile == val &&
          this.value.first_name != null
        )
          return;
        let data = { mobile: val };
        this.entries = [data];
        let mobile = parsePhoneNumber(val);
        this.changeCountry(mobile);
        this.$emit("input", data);
      },
    },
    // search(val) {
    //   this.searchHelper(val);
    // },
  },
  methods: {
    debouncedInputChange() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.searchHelper(this.search);
      }, 300);
    },
    searchHelper(val) {
      if (!val) {
        this.$emit("updateCustomer", {});
        return;
      }
      if (this.isSearchLoading) return;
      if (!val || (this.value != null && this.value.mobile == val)) {
        // this.customerDoesNotExist = false;
        let countryCode = "AE";
        if (val.length > 9) {
          const mobileRegex = /\+?(?:998|996|995|994|993|992|977|976|975|974|973|972|971|970|968|967|966|965|964|963|962|961|960|886|880|856|855|853|852|850|692|691|690|689|688|687|686|685|683|682|681|680|679|678|677|676|675|674|673|672|670|599|598|597|595|593|592|591|590|509|508|507|506|505|504|503|502|501|500|423|421|420|389|387|386|385|383|382|381|380|379|378|377|376|375|374|373|372|371|370|359|358|357|356|355|354|353|352|351|350|299|298|297|291|290|269|268|267|266|265|264|263|262|261|260|258|257|256|255|254|253|252|251|250|249|248|246|245|244|243|242|241|240|239|238|237|236|235|234|233|232|231|230|229|228|227|226|225|224|223|222|221|220|218|216|213|212|211|98|95|94|93|92|91|90|86|84|82|81|66|65|64|63|62|61|60|58|57|56|55|54|53|52|51|49|48|47|46|45|44\D?1624|44\D?1534|44\D?1481|44|43|41|40|39|36|34|33|32|31|30|27|20|7|1\D?939|1\D?876|1\D?869|1\D?868|1\D?849|1\D?829|1\D?809|1\D?787|1\D?784|1\D?767|1\D?758|1\D?721|1\D?684|1\D?671|1\D?670|1\D?664|1\D?649|1\D?473|1\D?441|1\D?345|1\D?340|1\D?284|1\D?268|1\D?264|1\D?246|1\D?242|1)/;
          // Assuming data.mobile is the string you want to match against
          const countryCodeMatch = val.match(mobileRegex);

          if (countryCodeMatch) {
            let code = this.countries.find(
              (item) => item.code == countryCodeMatch[0]
            );
            if (code) {
              countryCode = code.iso_2;
            }
          }
        }
        if (!countryCode) {
          countryCode = "AE";
        }
        let number = parsePhoneNumberFromString(val, countryCode);
        this.changeCountry(number);
        return;
      }
      //TODO: Customer exist check
      // if (this.customerDoesNotExist) {
      //   this.entries = [{ mobile: val, name: null, email: null }];
      //   return;
      // }
      while (val.charAt(0) === "0" || val.charAt(0) === "+") {
        val = val.substr(1);
      }
      this.search = val;
      if (/^[a-zA-Z]+$/.test(val)) return;
      if (val && val.length > 2) this.searchCustomer(val);
    },
    searchCustomer(mobile) {
      this.isSearchLoading = true;
      let url = "mobile=" + mobile;
      if(this.venueId){
        url += "&venueId="+this.venueId; 
      }
      if (this.isUserCheck) url += "&is_user_check=" + this.isUserCheck;
      this.$http
        .get(`venues/customers/vc-search?${url}`)
        .then((response) => {
          if (response.status == 200) {
            this.isSearchLoading = false;
            let data = response.data.data;
            if (data.length == 0) {
              // this.customerDoesNotExist = true;
              data.push({ mobile: mobile, name: null, email: null, id: null });
            }
            this.entries = data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    entryShift() {
      if (this.entries.length == 1) {
        this.changeCustomerData(this.entries[0]);
      }
    },
    changeCustomerData(data) {
      if (!data) {
        return;
      }
      let countryCode =
        this.country && this.country.iso_2 ? this.country.iso_2 : null;

      if (data.mobile.length > 9) {
        const mobileRegex = /\+?(?:998|996|995|994|993|992|977|976|975|974|973|972|971|970|968|967|966|965|964|963|962|961|960|886|880|856|855|853|852|850|692|691|690|689|688|687|686|685|683|682|681|680|679|678|677|676|675|674|673|672|670|599|598|597|595|593|592|591|590|509|508|507|506|505|504|503|502|501|500|423|421|420|389|387|386|385|383|382|381|380|379|378|377|376|375|374|373|372|371|370|359|358|357|356|355|354|353|352|351|350|299|298|297|291|290|269|268|267|266|265|264|263|262|261|260|258|257|256|255|254|253|252|251|250|249|248|246|245|244|243|242|241|240|239|238|237|236|235|234|233|232|231|230|229|228|227|226|225|224|223|222|221|220|218|216|213|212|211|98|95|94|93|92|91|90|86|84|82|81|66|65|64|63|62|61|60|58|57|56|55|54|53|52|51|49|48|47|46|45|44\D?1624|44\D?1534|44\D?1481|44|43|41|40|39|36|34|33|32|31|30|27|20|7|1\D?939|1\D?876|1\D?869|1\D?868|1\D?849|1\D?829|1\D?809|1\D?787|1\D?784|1\D?767|1\D?758|1\D?721|1\D?684|1\D?671|1\D?670|1\D?664|1\D?649|1\D?473|1\D?441|1\D?345|1\D?340|1\D?284|1\D?268|1\D?264|1\D?246|1\D?242|1)/;

        // Assuming data.mobile is the string you want to match against
        const countryCodeMatch = data.mobile.match(mobileRegex);

        if (countryCodeMatch) {
          let code = this.countries.find(
            (item) => item.code == countryCodeMatch[0]
          );
          if (code) {
            countryCode = code.iso_2;
          }
        }
      }
      if (!countryCode) {
        countryCode = "AE";
      }

      // console.log("countryCode");
      // console.log(countryCode);
      // console.log("data.mobile");
      // console.log(data.mobile);
      let number = parsePhoneNumberFromString(data.mobile, countryCode);
      if (countryCode != number.country) {
        this.changeCountry(number);
      }
      if (!number.isValid()) {
        this.showError("Not a valid mobile number");
        return;
      }
      let customer = {};
      customer.mobile = number.number;
      if (data.first_name) {
        customer.name = `${data.first_name}${
          data.last_name ? " " + data.last_name : ""
        }`;
        customer.email = data.email;
        customer.country_id = data.country_id;
        customer.gender = data.gender;
        customer.dob = data.dob;
        customer.age_group = data.age_group;
        customer.last_name = data.last_name;
        customer.first_name = data.first_name;
        customer.image_path = data.profile_image;
        customer.customer_id = data.id;
        customer.opt_marketing = data.opt_marketing;
        customer.user_id = data.user_id;
        if (data.venue_customer_tags && data.venue_customer_tags.length) {
          const tags = [];
          data.venue_customer_tags.map((cvt) => {
            tags.push({ id: cvt.tag.id, name: cvt.tag.name });
          });
          customer.customer_tag = tags;
        }
        // customer.customer_tag = data.tag;
        customer.customer_documents = data.customer_documents;
        this.country = countryCode;
      }

      this.$emit("updateCustomer", customer);
      data.mobile = number.number;

      this.$emit("input", data);
    },
    mobileRules() {
      const rules = [];
      const rule = (v) => !!v || "Mobile number is required";
      rules.push(rule);
      let countryCode =
        this.country && this.country.iso_2 ? this.country.iso_2 : "AE";
      if (this.value != null && this.value.mobile != null) {
        let parseData = parsePhoneNumberFromString(
          this.value.mobile || "",
          countryCode
        );
        if (parseData != null && parseData.isValid() == false)
          rules.push(`Not a valid mobile number`);
      }
      return rules;
    },
    changeCountry(number) {
      this.country = this.countries.find(
        (item) => item.iso_2 == number.country
      );
    },
  },
};
</script>

<style>

</style>
