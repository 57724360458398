<template>
  <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      app
      clipped
      width="230"
      dark
      color="#112A46"
      class="qp-nav-drawer"
  >
    <template v-slot:prepend>
      <SvgIcon class="p-x-5 p-t-5 p-b-8" img-styles="width:100%;height:auto;max-width:112px" :icon="Logo"/>
    </template>
    <v-list dense>
      <v-list-item-group
          v-model="item"
          class="navigation_krews"
          color="primary"
          mandatory
      >
        <v-list-item
            v-if="checkReadPermission($modules.general.dashboard.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="dashboard"
            @click="menuNavigation('Dashboard')"
        >
          <SvgIcon class="gap-x-4" text="Dashboard">
            <template v-slot:icon>
              <DashboardIcon />
            </template>
          </SvgIcon>
        </v-list-item>
        <v-list-item
            v-if="
            checkReadPermission($modules.events.schedule.slug) ||
            checkReadPermission($modules.workshops.schedule.slug) ||
            checkReadPermission($modules.facility.schedule.slug)
          "
            active-class="navbar-active"
            class="navbar-item" link
            value="schedule"
            @click="schedulePageSelection"
        >
          <SvgIcon class="gap-x-4" text="Schedule">
            <template v-slot:icon>
              <ScheduleIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="checkReadPermission($modules.facility.management.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="grounds"
            @click="menuNavigation('Facilities')"
        >
          <SvgIcon class="gap-x-4" text="Facility">
            <template v-slot:icon>
              <FacilityIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="checkReadPermission($modules.space.management.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="spaces"
            @click="menuNavigation('Spaces')"
        >
          <SvgIcon class="gap-x-4" text="Space">
            <template v-slot:icon>
              <SpaceIcon/>
            </template>
          </SvgIcon>

        </v-list-item>

        <v-list-item
            v-if="checkReadPermission($modules.events.management.slug)"
            active-class="navbar-active"
            class="navbar-item"
            link

            value="events"
            @click="menuNavigation('Events')"
        >
          <SvgIcon class="gap-x-4" text="Events">
            <template v-slot:icon>
              <EventsIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="checkReadPermission($modules.workshops.management.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="academy"
            @click="menuNavigation('Workshops')"
        >
          <SvgIcon class="gap-x-4" text="Academy">
            <template v-slot:icon>
              <AcademyIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="checkReadPermission($modules.trainers.dashboard.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="trainers"
            @click="menuNavigation('Trainers')"
        >
          <SvgIcon class="gap-x-4" text="Trainers">
            <template v-slot:icon>
              <TrainerIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="checkReadPermission($modules.clients.customers.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="clients"
            @click="menuNavigation('Customers')"
        >
          <SvgIcon class="gap-x-4" text="Customers">
            <template v-slot:icon>
              <CustomersIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="checkReadPermission($modules.corporate.dashboard.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="corporates"
            @click="menuNavigation('CorporateDashboard')"
        >
          <SvgIcon class="gap-x-4" text="Corporates">
            <template v-slot:icon>
              <CorporatesIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="checkReadPermission($modules.memberships.dashboard.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="memberships"
            @click="menuNavigation('Memberships')"
        >
          <SvgIcon class="gap-x-4" text="Memberships">
            <template v-slot:icon>
              <MembershipsIcon/>
            </template>
          </SvgIcon>
        </v-list-item>
        <v-list-item
            v-else-if="checkReadPermission($modules.memberships.schedule.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="memberships"
            @click="menuNavigation('MembershipAttendanceLog')"
        >
          <SvgIcon class="gap-x-4" text="Memberships">
            <template v-slot:icon>
              <MembershipsIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
          value="outlets"
          link
          @click="menuNavigation('Outlets')"
          v-if="checkReadPermission($modules.outlet.dashboard.slug)"
          class="navbar-item"
          active-class="navbar-active"

        >
          <SvgIcon class="gap-x-4" text="Outlets">
            <template v-slot:icon>
              <OutletIcon/>
            </template>
          </SvgIcon>

        </v-list-item>

<!--        <v-divider-->
<!--          v-if="checkReadPermission($modules.outlet.dashboard.slug)"-->
<!--        ></v-divider>-->

        <v-list-item
          value="sales-teams"
          link
          @click="menuNavigation('SalesTeams')"
          v-if="checkReadPermission($modules.salesTeam.dashboard.slug)"
          class="navbar-item"
          active-class="navbar-active"

        >
          <SvgIcon class="gap-x-4" text="Sales Teams">
            <template v-slot:icon>
              <SalesTeamIcon/>
            </template>
          </SvgIcon>

        </v-list-item>

<!--        <v-divider-->
<!--          v-if="checkReadPermission($modules.salesTeam.dashboard.slug)"-->
<!--        ></v-divider>-->

        <v-list-item
            v-if="checkReadPermission($modules.b2b.dashboard.slug)"
            active-class="navbar-active"
            class="navbar-item"
            link
            value="b2b"
            @click="menuNavigation('B2bDashboard')"
        >
          <SvgIcon class="gap-x-4" text="Partners">
            <template v-slot:icon>
              <B2BIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="checkReadPermission($modules.pos.dashboard.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="pos"
            @click="menuNavigation('PosView')"
        >
          <SvgIcon class="gap-x-4" text="Retail">
            <template v-slot:icon>
              <RetailsIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="
              checkReadPermission($modules.shop.dashboard.slug) &&(
              checkReadPermission($modules.facility.schedule.slug)||
              checkReadPermission($modules.events.schedule.slug)||
              checkReadPermission($modules.memberships.dashboard.slug)||
              checkReadPermission($modules.trainers.dashboard.slug)||
              checkReadPermission($modules.workshops.schedule.slug)||
              checkReadPermission($modules.pos.dashboard.slug))
            "
            active-class="navbar-active"
            class="navbar-item"
            link
            value="Shop"
            @click="menuNavigation(getShopRoute())"
        >
          <SvgIcon class="gap-x-4" text="Shop">
            <template v-slot:icon>
              <ShopIcon/>
            </template>
          </SvgIcon>
        </v-list-item>
        <v-list-item
            v-if="
            checkReadPermission($modules.sales.graph.slug) ||
              checkReadPermission($modules.sales.logs.slug) ||
              checkReadPermission($modules.sales.credits.slug) ||
              checkReadPermission($modules.sales.refunds.slug) ||
              checkReadPermission($modules.sales.void.slug)
          "
            active-class="navbar-active"
            class="navbar-item" link

            value="sales"
            @click="
            menuNavigation(
              `${
                checkReadPermission($modules.sales.graph.slug)
                  ? 'Sales'
                  : checkReadPermission($modules.sales.logs.slug)
                  ? 'Logs'
                  : checkReadPermission($modules.sales.refunds.slug)
                  ? 'Refunds'
                  : checkReadPermission($modules.sales.void.slug)
                  ? 'Cancellations'
                  : 'Credits'
              }`
            )
          "
        >
          <SvgIcon class="gap-x-4" text="Finance">
          <template v-slot:icon>
            <FinancialsIcon/>
          </template>
          </SvgIcon>
        </v-list-item>

        <!-- Master Pricing -->
        <v-list-item
            v-if="checkReadPermission($modules.masterPricing.management.slug)"
            active-class="navbar-active"
            class="navbar-item" link
            value="pricing"
            @click="menuNavigation('Pricing')"
        >
          <SvgIcon class="gap-x-4" text="Master Pricing">
          <template v-slot:icon>
            <PricingIcon/>
          </template>
          </SvgIcon>

        </v-list-item>
        <!-- Reports -->
        <v-list-item
            v-if="reportCheck()"
            active-class="navbar-active"
            class="navbar-item" link

            value="reports"
            @click="menuNavigation('Reports')"
        >
          <SvgIcon class="gap-x-4" text="Reports">
          <template v-slot:icon>
            <ReportsIcon/>
          </template>
          </SvgIcon>

        </v-list-item>
        <!-- End Reports -->

        <v-list-item
            v-if="checkReadPermission($modules.promotions.management.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="promotions"
            @click="menuNavigation('Promotions')"
        >
          <SvgIcon class="gap-x-4" text="Promotions">
            <template v-slot:icon>
              <PromotionsIcon/>
            </template>
          </SvgIcon>
        </v-list-item>


        <v-list-item
            v-if="checkReadPermission($modules.offers.management.slug)"
            active-class="navbar-active"
            class="navbar-item" link
            value="offers"
            @click="menuNavigation('Offers')"
        >
          <SvgIcon class="gap-x-4" text="Offers">
            <template v-slot:icon>
              <OffersIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="checkReadPermission($modules.vouchers.management.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="vouchers"
            @click="menuNavigation('Vouchers')"
        >
          <SvgIcon class="gap-x-4" text="Vouchers">
            <template v-slot:icon>
              <VouchersIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="checkMarketingPermission()"
            active-class="navbar-active"
            class="navbar-item" link

            value="marketing"
            @click="menuNavigation('Marketing')"
        >
          <SvgIcon class="gap-x-4" text="Marketing">
            <template v-slot:icon>
              <MarketingIcon/>
            </template>
          </SvgIcon>
        </v-list-item>
<!--        <v-divider-->
<!--          v-if="checkReadPermission($modules.marketing.dashboard.slug)"-->
<!--        ></v-divider>-->

        <v-list-item
          value="lead-tracking"
          link
          class="navbar-item"
          active-class="navbar-active"
          v-if="checkReadPermission($modules.leadTracking.management.slug)"
          @click="menuNavigation('leadTracking')"
        >

          <SvgIcon class="gap-x-4" text="Lead Tracking">
            <template v-slot:icon>
              <LeadTrackerIcon/>
            </template>
          </SvgIcon>

        </v-list-item>
<!--        <v-divider-->
<!--          v-if="checkReadPermission($modules.leadTracking.management.slug)"-->
<!--        ></v-divider>-->

        <v-list-item
            v-if="checkReadPermission($modules.mediaLibrary.management.slug)"
            active-class="navbar-active"
            class="navbar-item" link
            value="mediaLibrary"
            @click="menuNavigation('MediaLibrary')"
        >
          <SvgIcon class="gap-x-4" text="Media Library">
            <template v-slot:icon>
              <MediaLibraryIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="checkReadPermission($modules.b2cconfig.general.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="b2cconfig"
            @click="menuNavigation('B2CGeneralConfig')"
        >
          <SvgIcon class="gap-x-4" text="Qube">
            <template v-slot:icon>
              <QubeIcon/>
            </template>
          </SvgIcon>
        </v-list-item>
        <v-list-item
            v-if="checkReadPermission($modules.qpoints.management.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="logs"
            @click="menuNavigation('QPointsLogs')"
        >
          <SvgIcon class="gap-x-4" text="QPoints">
            <template v-slot:icon>
              <PromotionsIcon/>
            </template>
          </SvgIcon>
        </v-list-item>

        <v-list-item
            v-if="checkReadPermission($modules.general.profile.slug)"
            active-class="navbar-active"
            class="navbar-item" link

            value="profile"
            @click="menuNavigation('Profile')"
        >
          <SvgIcon class="gap-x-4" text="Profile">
            <template v-slot:icon>
              <ProfileIcon/>
            </template>
          </SvgIcon>
        </v-list-item>
<!--        <v-divider-->
<!--          v-if="checkReadPermission($modules.customerSupport.support.slug)"-->
<!--        ></v-divider>-->

        <v-list-item
            active-class="navbar-active"
            class="navbar-item" link
          value="customer-support"
          @click="menuNavigation('CustomerSupport')"
          v-if="checkReadPermission($modules.customerSupport.support.slug)"
        >
          <SvgIcon class="gap-x-4" text="Customer Support">
            <template v-slot:icon>
              <CustomerSupportIcon/>
            </template>
          </SvgIcon>

        </v-list-item>
<!--        <v-divider-->
<!--          v-if="checkReadPermission($modules.customerSupport.support.slug)"-->
<!--        ></v-divider>-->
      </v-list-item-group>
    </v-list>
    <!--    <template v-slot:append>-->
    <!--      <v-divider></v-divider>-->
    <!--      <v-list-item link color="primary" @click.stop="mini = !mini" dense>-->
    <!--        <v-list-item-icon>-->
    <!--          <v-icon color="menu_icon">{{-->
    <!--            mini ? "mdi-chevron-double-right" : "mdi-chevron-double-left"-->
    <!--          }}</v-icon>-->
    <!--        </v-list-item-icon>-->
    <!--        <v-list-item-content>-->
    <!--          <v-list-item-title>Collapse Sidebar</v-list-item-title>-->
    <!--        </v-list-item-content>-->
    <!--      </v-list-item>-->
    <!--    </template>-->
  </v-navigation-drawer>
</template>
<script>
import {EventBus} from "@/main";
import DashboardIcon from '@/assets/images/nav_icon/Dashboard.svg';
import AcademyIcon from '@/assets/images/nav_icon/Academy.svg';
import B2BIcon from '@/assets/images/nav_icon/B2B.svg';
import CorporatesIcon from '@/assets/images/nav_icon/Corporates.svg';
import CustomersIcon from '@/assets/images/nav_icon/Customers.svg';
import EventsIcon from '@/assets/images/nav_icon/Events.svg';
import FacilityIcon from '@/assets/images/nav_icon/Facility.svg';
import MembershipsIcon from '@/assets/images/nav_icon/Memberships.svg';
import ProfileIcon from '@/assets/images/nav_icon/Profile.svg';
import PromotionsIcon from '@/assets/images/nav_icon/Promotions.svg';
import QubeIcon from '@/assets/images/nav_icon/Qube.svg';
import MediaLibraryIcon from '@/assets/images/nav_icon/MediaLibrary.svg';
import ReportsIcon from '@/assets/images/nav_icon/Reports.svg';
import FinancialsIcon from '@/assets/images/nav_icon/financials.svg';
import RetailsIcon from '@/assets/images/nav_icon/retails.svg';
import SpaceIcon from '@/assets/images/nav_icon/Space.svg';
import TrainerIcon from '@/assets/images/nav_icon/Trainer.svg';
import VouchersIcon from '@/assets/images/nav_icon/Vouchers.svg';
import ScheduleIcon from '@/assets/images/nav_icon/schedule-v2.svg';
import MarketingIcon from '@/assets/images/nav_icon/Marketing.svg';
import CustomerSupportIcon from '@/assets/images/nav_icon/CustomerSupport.svg';
import LeadTrackerIcon from '@/assets/images/nav_icon/LeadTracker.svg';
import SalesTeamIcon from '@/assets/images/nav_icon/SalesTeam.svg';
import OutletIcon from '@/assets/images/nav_icon/Outlet.svg';
import OffersIcon from '@/assets/images/nav_icon/offers.svg';
import ShopIcon from '@/assets/images/nav_icon/shop.svg';
import PricingIcon from '@/assets/images/nav_icon/pricing.svg';
import Logo from '@/assets/images/logo-white.png';
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {
    SvgIcon,
    DashboardIcon,
    AcademyIcon,
    B2BIcon,
    MediaLibraryIcon,
    CorporatesIcon,
    CustomersIcon,
    EventsIcon,
    FacilityIcon,
    MembershipsIcon,
    ProfileIcon,
    PromotionsIcon,
    QubeIcon,
    ReportsIcon,
    FinancialsIcon,
    RetailsIcon,
    SpaceIcon,
    TrainerIcon,
    VouchersIcon,
    ScheduleIcon,
    MarketingIcon,
    CustomerSupportIcon,
    LeadTrackerIcon,
    SalesTeamIcon,
    OutletIcon,
    ShopIcon,
    PricingIcon,
    OffersIcon
  },
  data: () => ({
    drawer: true,
    item: 0,
    mini: false,
    Logo
  }),

  created() {
    EventBus.$on("changeIt", (data) => {
      this.drawer = data;
    });
    this.item = this.$route.meta.item;
  },
  watch: {
    $route() {
      this.item = this.$route.meta.item;
    },
  },
  methods: {
    menuNavigation(route) {
      if (this.$router.currentRoute.name != route) {
        this.$router.push({name: route}, () => {
        });
      }
    },
    schedulePageSelection() {
      if (this.checkReadPermission(this.$modules.facility.schedule.slug)) {
        this.$router.push({name: "Schedule"}, () => {
        });
      } else if (this.checkReadPermission(this.$modules.events.schedule.slug)) {
        this.$router.push({name: "EventSchedule"}, () => {
        });
      } else if (
          this.checkReadPermission(this.$modules.workshops.schedule.slug)
      ) {
        this.$router.push({name: "WorkshopSchedule"}, () => {
        });
      }
    },
    reportCheck() {
      return (
          this.checkReadPermission(this.$modules.reports.cashier.slug) ||
          this.checkReadPermission(this.$modules.reports.service.slug) ||
          this.checkReadPermission(this.$modules.reports.discount.slug) ||
          this.checkReadPermission(this.$modules.reports.product.slug) ||
          this.checkReadPermission(this.$modules.reports.payment.slug) ||
          this.checkReadPermission(this.$modules.reports.bills.slug)
      );
    },
    checkMarketingPermission() {
      return (
          this.checkReadPermission(this.$modules.marketing.facebook.slug) ||
          this.checkReadPermission(this.$modules.marketing.instagram.slug) ||
          this.checkReadPermission(this.$modules.marketing.twitter.slug) ||
          this.checkReadPermission(this.$modules.marketing.youtube.slug) ||
          this.checkReadPermission(this.$modules.marketing.snapchat.slug) ||
          this.checkReadPermission(this.$modules.marketing.krews.slug) ||
          this.checkReadPermission(this.$modules.marketing.sms.slug) ||
          this.checkReadPermission(this.$modules.marketing.email.slug)
      );
    },
    getShopRoute(){
      if(this.checkReadPermission(this.$modules.facility.schedule.slug)){
        return 'ShopFacility'
      }else if(this.checkReadPermission(this.$modules.events.schedule.slug)){
        return 'ShopEvents'
      }else if(this.checkReadPermission(this.$modules.memberships.dashboard.slug)){
        return 'ShopMemberships'
      }else if(this.checkReadPermission(this.$modules.trainers.dashboard.slug)){
        return 'ShopTrainers'
      }else if(this.checkReadPermission(this.$modules.workshops.schedule.slug)){
        return 'ShopWorkshops'
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.navigation_krews {
  font-weight: 500;
  color: teal;
}

.stage_ic,
.stage_ic_dark {
  background-image: url("../../assets/images/nav_icon/stage.png");
  padding: 12px;
  background-size: contain;
  background-position: center;
}

.stage_ic_dark {
  background-image: url("../../assets/images/nav_icon/stage_dark.png");
}

.chart_ic,
.chart_ic_dark {
  background-image: url("../../assets/images/nav_icon/graph.png");
  padding: 10px;
  background-size: contain;
}

.chart_ic_dark {
  background-image: url("../../assets/images/nav_icon/graph_dark.png");
}

.marketing_ic,
.marketing_ic_dark {
  background-image: url("../../assets/images/nav_icon/marketing.png");
  padding: 10px;
  background-size: contain;
}

.marketing_ic_dark {
  background-image: url("../../assets/images/nav_icon/marketing_dark.png");
}

.personal-trainer_ic,
.personal-trainer_ic_dark {
  background-image: url("../../assets/images/nav_icon/personal-trainer.png");
  padding: 10px;
  background-size: contain;
}

.personal-trainer_ic_dark {
  background-image: url("../../assets/images/nav_icon/personal-trainer_dark.png");
}

.btn_chevron {
  margin-top: 50%;
  z-index: 10000;
}

.navbar-item {
  border-left: 6px solid transparent;
  border-bottom: none;
  font-size: 1rem;
  font-weight: 400;
  opacity: 0.6;
}

.navbar-active {
  background-color: #112A461A !important;
  border-left-color: #4FAEAF;
  color: #FFFFFF !important;
  opacity: 1;
  font-weight: 500;

}

.navbar-active svg{
  stroke: #FFFFFF;
  stroke-width: 0.1;
}
.v-navigation-drawer{
  top: 0 !important;
  max-height: 100% !important;
}
</style>
