<template>
  <div>
    <div class="d-flex justify-start align-center mb-6">
      <div class="d-flex p-2  bg-white bordered w-fit tabs">
        <template v-for="(tab) in tabs">
          <div v-if="checkWritePermission(tab.permission)" v-bind:key="tab.name" class="nv_item d-flex pointer tab" @click="gotoTab(tab.value)">
            <SvgIcon
                :class="{
                'qp-tab-nav-is-active': tab.value=== currentTab,
                'qp-tab-nav': tab.path && !tab.path.includes($route.path)
              }"
                :text="tab.name"
                class="text-thin-gray"
            >
              <template v-slot:icon>
                <component :is="tab.icon" :component="tab.icon" />
              </template>
            </SvgIcon>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import FacilityIcon from "@/assets/images/facilities/facility.svg";
import EventsIcon from "@/assets/images/events/event.svg";
import MembershipIcon from "@/assets/images/memberships/memberships.svg";
import TrainerIcon from "@/assets/images/trainers/trainers_icon.svg";
import AcademyIcon from "@/assets/images/workshops/workshops.svg";
import RetailIcon from "@/assets/images/retail/retail.svg";

export default {
  name: "MasterPricingTabs",
  components: {SvgIcon},
  props:{
    currentTab:{ default:null, type: String, required: true },
  },
  methods:{
    gotoTab(tab){
      this.$emit("gotoTab",tab);
    },
  },
  data(){
    return{
      tabs: [
        {
          name: "Facility",
          icon: FacilityIcon,
          value:'facility',
          permission: this.$modules.facility.schedule.slug
        },
        {
          name: "Events",
          icon: EventsIcon,
          value:'events',
          permission: this.$modules.events.schedule.slug
        },
        {
          name: "Memberships",
          icon: MembershipIcon,
          value:'memberships',
          permission: this.$modules.memberships.dashboard.slug
        },
        {
          name: "Trainers",
          icon: TrainerIcon,
          value:'trainers',
          permission: this.$modules.trainers.dashboard.slug
        },
        {
          name: "Academy",
          icon: AcademyIcon,
          value:'academy',
          permission: this.$modules.workshops.schedule.slug
        },
        {
          name: "Retail",
          icon: RetailIcon,
          value:'retail',
          permission: this.$modules.pos.dashboard.slug
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>