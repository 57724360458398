<template>
  <v-dialog v-model="productEditModal" scrollable width="800px" persistent>
    <v-form ref="form" v-model="valid">
      <v-card class="card-rounded-bottom" style="min-height: 200px">
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold"
                         text="Update Product" style="color: black">
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <div class="mt-8 mb-4">
            <div class="d-block mb-4">
              <span class="black-text product-name">
                Name: {{ product.name }}
              </span>
            </div>
            <v-row>
              <v-col md="4" lg="4">
                <label for="">Price (Pre Tax)</label>
                <v-text-field
                    v-model="product.pre_tax_price"
                    outlined
                    required
                    :rules="[
                    (v) => {
                      if (v >= 0) {
                        return true;
                      }
                      return 'Price is required';
                    },
                    ]"
                    class="q-text-field shadow-0"
                    :prefix="currencyCode"
                    hide-details="auto"
                    @change="calculatePricingTaxVariation($event, 'pre')"
                    dense
                ></v-text-field>
              </v-col>
              <v-col md="4" lg="4">
                <label for="">Tax type</label>
                <v-select
                    v-model="product.tax_type_id"
                    item-value="value"
                    item-text="text"
                    hint="Required tax"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :rules="[(v) => !!v || 'Tax type is required']"
                    :items="taxTypes"
                    hide-details="auto"
                    outlined
                    @change="taxChange"
                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                    dense

                ></v-select>
              </v-col>
              <v-col md="4" lg="4">
                <label for="">Price (Post Tax) </label>
                <v-text-field
                    v-model="product.total_price"
                    outlined
                    :prefix="currencyCode"
                    required
                    :rules="[
                    (v) => {
                      if (v >= 0) {
                        return true;
                      }
                      return 'Price is required';
                    },
                    ]"
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    @change="calculatePricingTaxVariation($event, 'post')"
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

        </v-card-text>
        <v-card-actions class="card-footer">
          <div class="d-flex w-full justify-end align-center mt-0">
            <v-btn
                elevation="0"
                class="ma-2 white--text blue-color"
                @click="saveProduct"
            >Update
            </v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  name: "ProductEditModal",
  components: {SvgIcon},
  props: {
    productEditModal: {type: Boolean, default: false},
    productId: {type: Number, default: null},
  },
  computed: {
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
  },
  mounted() {
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
  },
  data() {
    return {
      valid: false,
      product: {
        id: null,
        name: null,
        tax_type_id: 1,
        price: null,
        pre_tax_price: null,
        total_price: null,
        tax_amount: null,
      },
    }
  },
  watch: {
    productId(val) {
      if (val != null) {
        this.editProduct();
      }
    }
  },
  methods: {
    saveProduct(){
      this.$emit('saveProduct', this.product);
    },
    editProduct() {
      this.showLoader("Loading product");
      this.$http.get(`venues/master-pricing/get/product/${this.productId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.product = response.data.data;
              this.product.pre_tax_price = this.product.price;
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
    taxChange() {
      if (this.product.price) {
        this.calculateTaxVariation(
            this.product.price,
            "pre"
        );
      } else if (this.product.total_price) {
        this.calculateTaxVariation(
            this.product.total_price,
            "post"
        );
      }
    },

    calculateTaxVariation(amount, type) {
      let taxTypeId = this.product.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
            .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      if (priceData) {
        this.product.price = priceData.price;
        this.product.pre_tax_price = priceData.price.toFixed(2);
        this.product.total_price = priceData.total_price.toFixed(2);
      }
      this.$forceUpdate();
    },

    calculatePricingTaxVariation(amount, type) {
      let taxTypeId = this.product.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
            .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      if (priceData) {
        this.product.price = priceData.price;
        this.product.pre_tax_price = priceData.price.toFixed(2);
        this.product.total_price = priceData.total_price.toFixed(2);
      }
      this.$forceUpdate();
    },
    close() {
      this.$refs.form.resetValidation();
      this.$emit("closeProductEditModal");
    }
  }
}
</script>

<style scoped>
.product-name {
  font-size: 16px !important;
  font-weight: 500;
}
</style>