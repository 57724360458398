<template>
  <v-img
    :height="height"
    :src="image ? s3BucketURL + image : images[defaultImage]"
    :width="width ? width : 'auto'"
    :contain="!contain"
    :max-height="maxHeight"
    :max-width="maxWidth"
    aspect-ratio="0"
    v-bind="$attrs"
  >
    <slot name="overlay"></slot>
  </v-img>
</template>

<script>
import images from "@/utils/images";
export default {
  props: {
    image: { type: String, default: null },
    height: { type: Number, default: 120 },
    width: { type: Number, default: null },
    maxHeight:{ type: String, default: '' },
    maxWidth:{ type: String, default: '' },
    defaultImage: { type: String, default: "default" },
    contain: { type: Boolean, default: true },
  },
  data() {
    return {
      images: images,
    };
  },
};
</script>

<style>
</style>