<template>
  <v-container fluid>
    <v-row>
<!--      &lt;!&ndash; Retail List &ndash;&gt;-->
<!--      <v-col cols="3" md="3" class="pr-0">-->
<!--        <v-list class="fixed-height py-0  bordered rounded-t-2">-->
<!--          <v-list-item class="list-item list-heading">-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title >Retail List</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->
<!--          <v-list-item-->
<!--              v-for="(retail, index) in retail"-->
<!--              :key="index"-->
<!--              @click="selectRetail(retail)"-->
<!--              :class="{ 'blue&#45;&#45;text': selectedRetail.id === retail.id }"-->
<!--              class="list-item border-bottom"-->
<!--          >-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>{{ retail.name }}</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->
<!--        </v-list>-->
<!--      </v-col>-->

      <!-- Packages Table -->
      <v-col cols="12"  md="12">
        <v-card class="card-background-silver rounded-2 shadow-0">
          <v-card-title>
            Retail
          </v-card-title>

          <v-card-text class="rounded-2 fixed-height">
            <v-simple-table class="table p-4 table-bordered overflow-y-auto" style="background-color: #FFFFFF; border-radius: 12px ;">
              <thead>
              <tr class="">
                <td>Product Name</td>
                <td>Price</td>
                <td>Tax</td>
                <td>Price (Inc Vat)</td>
                <td>Actions</td>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(product,
                    tp) in selectedRetail.products"
                  :key="tp"
              >
                <td>{{ product.name }}</td>
                <td>{{ product.price  | toCurrency }}</td>
                <td>{{ product.tax_amount  | toCurrency }}</td>
                <td>{{ product.total_price  | toCurrency }}</td>
                <td class="text-center">
                  <v-btn
                      icon
                      @click="editProduct(product)"
                  >
                    <EditIcon height="16" viewBox="0 0 20 20" width="16"/>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditIcon from "@/assets/images/tables/edit.svg";

export default {
  components: {EditIcon},
  mounted() {
    this.loadRetailProducts();
  },
  props:{
    refresh:{ type:Boolean, default: false},
  },
  watch: {
    refresh(){
      this.loadRetailProducts();
    }
  },
  data() {
    return {
      retail: [],
      selectedRetail: {
        name:'',
        products: [],
      },
      types:[
        {
          name:'Individual',
          key:'I'
        },
        {
          name:'Couple',
          key:'C'
        },
        {
          name:'Group',
          key:'G'
        },
      ],
    };
  },
  methods: {
    editProduct(product) {
      this.$emit('editProduct', product);
    },
    // selectRetail(retail) {
    //   this.selectedRetail = retail;
    //   this.loadRetailProducts();
    // },
    // loadRetail(){
    //   this.showLoader('Loading Retail');
    //   this.$http.get(`venues/master-pricing/get/retail`)
    //       .then((response) => {
    //         if (response.status == 200 && response.data.status) {
    //           this.retail = response.data.data;
    //           if(this.retail.length > 0){
    //             this.selectRetail(this.retail[0]);
    //           }
    //         }
    //       }).catch(error => {
    //     this.errorChecker(error);
    //     return false;
    //   }).finally(() => {
    //     this.hideLoader()
    //   })
    // },
    loadRetailProducts(){
      // if(!this.selectedRetail.id){
      //   return;
      // }
      this.selectedRetail.products = [];
      this.showLoader("Loading products");
      this.$http.get(`venues/master-pricing/get/products/retail`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.selectedRetail.products = response.data.data;
              this.$forceUpdate()
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
  },
}
</script>

<style scoped>
.list-item {
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  text-align: center;
}

.list-item.blue--text {
  background-color: #112A46;
}

.card-background-silver{
  background-color: #F0F5F9;
}

.list-item.v-list-item.v-list-item--link.theme--light.blue--text {
  color:white !important;
}

.rounded-t-2{
  border-radius: 0.5rem 0.5rem 0 0 !important;
}

.bordered {
  border: 1px solid #EAEAEA !important;
  overflow: hidden;
}

.list-heading{
  background: #E9F1F6 !important;
  color: black;
  font-weight: bold;
}

.fixed-height {
  max-height: calc(100vh - 200px);
  overflow: auto;
}

</style>