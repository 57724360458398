<template>
  <v-container>
    <MasterPricingTabs :currentTab="tab" @gotoTab="gotoTab"/>
    <div class="md-card shadow-0 rounded-2 bordered mt-4">
      <FacilityPricing @editProduct="editProduct" :refresh="refresh" v-if="tab === 'facility'"></FacilityPricing>
      <EventPricing @editProduct="editProduct" :refresh="refresh" v-if="tab === 'events'"></EventPricing>
      <TrainerPricing @editProduct="editProduct" :refresh="refresh" v-if="tab === 'trainers'"></TrainerPricing>
      <MembershipPricing @editProduct="editProduct" :refresh="refresh" v-if="tab === 'memberships'"></MembershipPricing>
      <AcademyPricing @editProduct="editProduct" :refresh="refresh" v-if="tab === 'academy'"></AcademyPricing>
      <RetailPricing @editProduct="editProduct" :refresh="refresh" v-if="tab === 'retail'"></RetailPricing>
    </div>
    <ProductEditModal
        @closeProductEditModal="closeProductEditModal"
        @saveProduct="saveProduct"
        :productEditModal="productEditModal"
        :productId="productId"
    />
  </v-container>
</template>
<script>
import MasterPricingTabs from "@/components/MasterPricing/MasterPricingTabs.vue";
import FacilityPricing from "@/components/MasterPricing/FacilityPricing.vue";
import EventPricing from "@/components/MasterPricing/EventPricing.vue";
import TrainerPricing from "@/components/MasterPricing/TrainerPricing.vue";
import MembershipPricing from "@/components/MasterPricing/MembershipPricing.vue";
import AcademyPricing from "@/components/MasterPricing/AcademyPricing.vue";
import RetailPricing from "@/components/MasterPricing/RetailPricing.vue";
import ProductEditModal from "@/components/MasterPricing/ProductEditModal.vue";

export default {
  components: {
    ProductEditModal,
    RetailPricing,
    AcademyPricing, MembershipPricing, TrainerPricing, EventPricing, FacilityPricing, MasterPricingTabs
  },
  mounted() {
    this.setTab()
  },
  data() {
    return {
      tab: '',
      productEditModal: false,
      productId:null,
      refresh:false,
    }
  },
  methods: {
    saveProduct(product){
      this.showLoader("Saving product");
      this.$http.post(`venues/master-pricing/get/product`,product)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.refresh = !this.refresh;
              this.closeProductEditModal();
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
    closeProductEditModal() {
      this.productEditModal = false;
      this.productId = null;
    },
    editProduct(product) {
      this.productId = product.id;
      this.productEditModal = true;
    },
    gotoTab(tab) {
      this.tab = tab;
    },
    setTab() {
      if (this.checkReadPermission(this.$modules.facility.schedule.slug)) {
        this.tab = 'facility'
      } else if (this.checkReadPermission(this.$modules.events.schedule.slug)) {
        this.tab = 'events'
      } else if (this.checkReadPermission(this.$modules.memberships.dashboard.slug)) {
        this.tab = 'memberships'
      } else if (this.checkReadPermission(this.$modules.trainers.dashboard.slug)) {
        this.tab = 'trainers'
      } else if (this.checkReadPermission(this.$modules.workshops.schedule.slug)) {
        this.tab = 'academy'
      } else if (this.checkReadPermission(this.$modules.pos.dashboard.slug)) {
        this.tab = 'retail'
      }
    }
  }
}
</script>

<style scoped>

</style>