import axios from "../../utils/request";
const state = {
  status: false,
  data: null,
};

const getters = {
  getSalesConfiguration: (state) => state,
};

const actions = {
  loadSalesConfiguration({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/configurations/sales")
        .then((response) => {
          if (response && response.status == 200 && response.data.status == true) {
            commit("setSalesConfiguration", response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
const mutations = {
  setSalesConfiguration: (state, data) => {
    state.status = true;
    state.data = data;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
