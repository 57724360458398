<template>
  <v-menu
      ref="datemenu"
      v-model="datemenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :value="dateFormatted"
          :label="label"
          readonly
          solo
          dense
          v-bind="attrs"
          v-on="on"
          :flat="flat"
          :class="className"
          :hide-details="hideDetails"
      ></v-text-field>
    </template>
    <v-date-picker
        :value="value"
        @input="emitChange"
        :range="range"
        no-title
        scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
          text
          color="primary"
          @click="
          () => {
            $emit('input', null);
            $emit('change');
            $refs.datemenu.save();
          }
        "
      >Clear</v-btn
      >
      <v-btn text color="primary" @click="datemenu = false">Cancel</v-btn>
      <v-btn
          text
          color="primary"
          @click="
          () => {
            $emit('change');
            $refs.datemenu.save();
          }
        "
      >OK</v-btn
      >
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  inheritAttrs: false,
  props: {
    value: { default: null },
    label: { type: String, default: "Select date" },
    className: { type: String, default: "" },
    dense: { type: Boolean, default: true },
    range: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
  },
  data() {
    return {
      datemenu: false,
    };
  },
  computed: {
    dateFormatted() {
      if (this.value == null) return null;
      if (Array.isArray(this.value)) return null;
      return moment(this.value, "YYYY-MM-DD").format("Do MMM YYYY");
    },
  },
  methods: {
    emitChange(v) {
      if (v) this.$emit("input", v);
    },
  },
};
</script>

<style>
</style>