import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./utils/request";
import vuetify from "./plugins/vuetify";
import common from "./mixins/common";
import "./utils/routePermission";
import "./utils/chart";
import "./utils/filter";
import "./utils/globalComponents";
import Modules from "./utils/moduleStructure";
import * as VueGoogleMaps from "vue2-google-maps";
import VueKonva from "vue-konva";

Vue.use(VueKonva);
Vue.mixin(common);

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBiK4-gaLpyLL2Zk42ubfL1VjMbXDPpi6w",
    libraries: "places,drawing",
  },
});
Vue.prototype.$http = axios;
Vue.prototype.$modules = Modules;
Vue.prototype.s3BucketURL = process.env.VUE_APP_S3_BUCKET_URL;

export const EventBus = new Vue();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
