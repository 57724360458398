<template>
  <v-dialog
    v-model="dialoge"
    persistent
    max-width="400"
    @input="$emit('close')"
  >
    <v-card>
      <v-card-title class="headline">{{
        title ? title : "Do you sure?"
      }}</v-card-title>
      <v-card-text class="pa-5">
        <span
          v-html="
            description ? description : 'Do you need to continue your action ?'
          "
        ></span>
        <slot name="content"></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="cancel">No</v-btn>
        <v-btn color="green darken-1" text @click="confirm" :disabled="disableConfirm">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    id: { type: Number, default: null },
    type: { type: String, default: "delete" },
    title: { type: String, default: "Are you sure?" },
    description: {
      type: String,
      default:
        "By clicking <b>Yes</b> you can confirm operation. Do you need to continue your action ?",
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disableConfirm: { type: Boolean, default: false }
  },

  watch: {
    id(val) {
      console.log(val)
      if (val != null) {
        this.dialoge = true;
      }
    },
  },
  data() {
    return {
      dialoge: false,
    };
  },
  methods: {
    confirm() {
      this.dialoge = false;
      this.$emit("confirm", { id: this.id, type: this.type, data: this.data });
    },
    cancel() {
      this.dialoge = false;
      this.$emit("close", { id: this.id, type: this.type, data: this.data });
    },
  },
};
</script>

<style></style>
