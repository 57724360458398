import axios from "../../utils/request";
import moment from "moment";

const getRepeatState = () => {
  return {
    repeats: [],
  };
};

const state = getRepeatState();

const getters = {
  getRepeats: (state) => state.repeats,
};

const actions = {
  loadRepeatDates(context, index) {
    return new Promise((resolve, reject) => {
      try {
        let repeatForm = context.state.repeats[index];

        let data = { index: index, repeated_dates: [] };
        if (
          repeatForm.repeatType != "Advanced" &&
          (!repeatForm.repeatNumber || !repeatForm.repeatEndType)
        ) {
          data.repeated_dates = [];
          context.commit("setRepeatDates", data);
          return resolve(data);
        }

        if (
          repeatForm.repeatEndType == "Count" &&
          !repeatForm.repeatOccurrence
        ) {
          data.repeated_dates = [];
          context.commit("setRepeatDates", data);
          return resolve(data);
        }
        if (repeatForm.repeatEndType == "Date" && !repeatForm.repeatEndDate) {
          data.repeated_dates = [];
          context.commit("setRepeatDates", data);
          return resolve(data);
        }
        if (repeatForm.repeatType == "Daily") {
          data.repeated_dates = helper.repeatDaily(repeatForm);
          context.commit("setRepeatDates", data);
          return resolve(data);
        } else if (repeatForm.repeatType == "Weekly") {
          if (repeatForm.repeatWeekdays.length == 0) {
            data.repeated_dates = [];
            context.commit("setRepeatDates", data);
            return resolve(data);
          }
          data.repeated_dates = helper.repeatWeekly(repeatForm);
          context.commit("setRepeatDates", data);
          return resolve(data);
        } else if (repeatForm.repeatType == "Monthly") {
          data.repeated_dates = helper.repeatMonthly(repeatForm);
          context.commit("setRepeatDates", data);
          return resolve(data);
        } else {
          data.repeated_dates = [];
          context.commit("setRepeatDates", data);
          return resolve(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  loadProductsForSelectedDate(context, params) {
    return new Promise((resolve, reject) => {
      const repeat = state.repeats[params.index];
      if (!repeat) {
        reject([]);
      }
      if (repeat.selected_dates.length == 0) {
        reject([]);
      }
      var formData = new FormData();
      formData.append("facility_id", params.facility_id);
      formData.append("start_time", repeat.start_time);
      formData.append("end_time", repeat.end_time);
      repeat.selected_dates.forEach((date, index) => {
        formData.append(`selected_dates[${index}]`, date);
      });
      axios
        .post(`venues/facilities/bookings/repeat/products`, formData)
        .then((response) => {
          if (response && response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            data.field = "products";
            data.index = params.index;
            context.commit("setRepeatFieldData", data);
            resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadRepeatUtils(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `venues/facilities/bookings/repeat/utils?facility_id=${params.facility_id}&date=${params.date}&venue_service_id=${params.venue_service_id}`
        )
        .then((response) => {
          if (response && response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            data.end_times = data.times.filter((item) => !item.no_end);
            data.start_times = data.times.filter((item) => !item.no_start);
            data.index = params.index;
            context.commit("setRepeatTimeData", data);
            resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadRepeatValidation(context, params) {
    return new Promise((resolve, reject) => {
      const repeat = state.repeats[params.index];
      if (!repeat) {
        reject([]);
      }
      if (repeat.repeated_dates.length == 0) {
        reject([]);
      }
      var formData = new FormData();
      formData.append("facility_id", params.facility_id);
      formData.append("start_time", repeat.start_time);
      formData.append("end_time", repeat.end_time);
      formData.append("venue_service_id", params.venue_service_id);
      repeat.repeated_dates.forEach((date, index) => {
        formData.append(`dates[${index}]`, date);
      });
      if(params.enableOvernightBooking){
        formData.append("enable_overnight_booking", 1);
      }else{
        formData.append("enable_overnight_booking", 0);
      }
      axios
        .post(`venues/facilities/bookings/repeat/validate`, formData)
        .then((response) => {
          if (response && response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            data.index = params.index;
            if (data.rental_product) {
              data.products = data.rental_product.products;
              data.field = "products";
              context.commit("setRepeatFieldData", data);
            }
            data.field = "excluded_dates";
            context.commit("setRepeatFieldData", data);
            data.field = "available_dates";
            context.commit("setRepeatFieldData", data);
            data.field = "selected_dates";
            data.selected_dates = data.available_dates;
            context.commit("setRepeatFieldData", data);
            resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addRepeatRow({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.init || state.repeats[state.repeats.length - 1].available_dates.length > 0) {
        let data = helper.getRepeatSkelton();
        if (params.facility_id) {
          data.facility_id = params.facility_id;
        }
        if (params.date) {
          data.date = params.date;
        }
        if (params.start_time) {
          data.start_time = params.start_time;
        }
        if (params.end_time) {
          data.end_time = params.end_time;
        }
        commit("setNewRepeat", data);
        resolve(data);
      }
      reject({
        status: false,
        data: {
          message: "Error! Please fill values to add new",
        },
      });
    });
  },
  removeRepeatRow({ state, commit }, index) {
    return new Promise((resolve, reject) => {
      if (state.repeats[index]) {
        commit("removeRepeatRow", index);
        if (state.repeats.length == 0) {
          let data = helper.getRepeatSkelton();
          commit("setNewRepeat", data);
        }
        resolve(state.repeats);
      }
      reject({
        status: false,
        data: {
          message: "Error! No data found",
        },
      });
    });
  },
 };

const helper = {
  conditionChecker(repeatEndType, startDate, endDate) {
    return repeatEndType == "Count"
      ? startDate.isBefore(endDate)
      : startDate.isSameOrBefore(endDate);
  },
  repeatDaily(repeatForm) {
    let startDate = moment(repeatForm.date, "YYYY-MM-DD");
    let endDate;
    if (
      repeatForm.repeatEndType == "Count" &&
      repeatForm.repeatOccurrence > 0
    ) {
      let days = repeatForm.repeatNumber * repeatForm.repeatOccurrence;
      endDate = moment(repeatForm.date, "YYYY-MM-DD").add(days, "days");
    } else {
      endDate = moment(repeatForm.repeatEndDate, "YYYY-MM-DD");
    }
    let repeatedDates = [];
    while (
      this.conditionChecker(repeatForm.repeatEndType, startDate, endDate)
    ) {
      repeatedDates.push(startDate.format("YYYY-MM-DD"));
      startDate.add(repeatForm.repeatNumber, "days");
    }
    return repeatedDates;
  },
  repeatWeekly(repeatForm) {
    repeatForm.repeatWeekdays = repeatForm.repeatWeekdays.sort((a, b) => a - b);
    let startDate = moment(repeatForm.date, "YYYY-MM-DD").day(
      repeatForm.repeatWeekdays[0]
    );
    const currentDate = moment(repeatForm.date, "YYYY-MM-DD");
    let endDate;

    if (repeatForm.repeatEndType == "Count") {
      let days = repeatForm.repeatNumber * repeatForm.repeatOccurrence * 7;
      endDate = moment(repeatForm.date, "YYYY-MM-DD")
        .add(days, "days")
        .day(repeatForm.repeatWeekdays[repeatForm.repeatWeekdays.length - 1]);
    } else {
      endDate = moment(repeatForm.repeatEndDate, "YYYY-MM-DD");
    }
    let repeatedDates = [];
    while (
      this.conditionChecker(repeatForm.repeatEndType, startDate, endDate)
    ) {
      repeatForm.repeatWeekdays.forEach((day) => {
        const weekday = startDate.day(day);
        if (
          weekday.day(day).isSameOrAfter(currentDate) &&
          weekday.day(day).isSameOrBefore(endDate)
        ) {
          repeatedDates.push(weekday.day(day).format("YYYY-MM-DD"));
        }
      });
      startDate
        .add(repeatForm.repeatNumber * 7, "days")
        .day(repeatForm.repeatWeekdays[0]);
    }
    return repeatedDates;
  },
  repeatMonthly(repeatForm) {
    let startDate = moment(repeatForm.date, "YYYY-MM-DD");
    let endDate;
    if (repeatForm.repeatEndType == "Count") {
      let days = repeatForm.repeatNumber * repeatForm.repeatOccurrence;
      endDate = moment(repeatForm.date, "YYYY-MM-DD").add(days, "months");
    } else {
      endDate = moment(repeatForm.repeatEndDate, "YYYY-MM-DD");
    }
    let repeatedDates = [];

    while (
      this.conditionChecker(repeatForm.repeatEndType, startDate, endDate)
    ) {
      repeatedDates.push(startDate.format("YYYY-MM-DD"));
      startDate.add(repeatForm.repeatNumber, "months");
    }
    return repeatedDates;
  },
  getRepeatSkelton() {
    return {
      repeatType: null,
      repeatEndType: null,
      repeatOccurrence: null,
      repeatWeekdays: [],
      repeatNumber: null,
      repeatEndDate: null,
      start_time: null,
      end_time: null,
      date: null,
      selected_dates: [],
      repeated_dates: [],
      available_dates: [],
      excluded_dates: [],
      start_times: [],
      end_times: [],
      times: [],
      products: [],
      facility_id: null,
    };
  },
};
const mutations = {
  resetRepeatState(state) {
    Object.assign(state, getRepeatState());
  },
  setRepeatDates: (state, data) => {
    state.repeats[data.index].repeated_dates = data.repeated_dates;
  },
  setRepeatTimeData: (state, data) => {
    state.repeats[data.index].times = data.times;
    state.repeats[data.index].start_times = data.start_times;
    state.repeats[data.index].end_times = data.end_times;
  },
  setRepeatFieldData: (state, data) => {
    state.repeats[data.index][data.field] = data[data.field];
  },
  setNewRepeat: (state, data) => {
    state.repeats.push(data);
  },
  removeRepeatRow(state, index) {
    state.repeats.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
