var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"800px","persistent":""},model:{value:(_vm.productEditModal),callback:function ($$v) {_vm.productEditModal=$$v},expression:"productEditModal"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"card-rounded-bottom",staticStyle:{"min-height":"200px"}},[_c('v-card-text',{staticClass:"border-bottom"},[_c('div',{staticClass:"row pt-1 border-bottom"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"d-flex justify-space-between align-center mt-2"},[_c('SvgIcon',{staticClass:"text-2xl font-semibold",staticStyle:{"color":"black"},attrs:{"text":"Update Product"}}),_c('v-btn',{staticClass:"shadow-0",attrs:{"fab":"","x-small":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])]),_c('div',{staticClass:"mt-8 mb-4"},[_c('div',{staticClass:"d-block mb-4"},[_c('span',{staticClass:"black-text product-name"},[_vm._v(" Name: "+_vm._s(_vm.product.name)+" ")])]),_c('v-row',[_c('v-col',{attrs:{"md":"4","lg":"4"}},[_c('label',{attrs:{"for":""}},[_vm._v("Price (Pre Tax)")]),_c('v-text-field',{staticClass:"q-text-field shadow-0",attrs:{"outlined":"","required":"","rules":[
                  function (v) {
                    if (v >= 0) {
                      return true;
                    }
                    return 'Price is required';
                  } ],"prefix":_vm.currencyCode,"hide-details":"auto","dense":""},on:{"change":function($event){return _vm.calculatePricingTaxVariation($event, 'pre')}},model:{value:(_vm.product.pre_tax_price),callback:function ($$v) {_vm.$set(_vm.product, "pre_tax_price", $$v)},expression:"product.pre_tax_price"}})],1),_c('v-col',{attrs:{"md":"4","lg":"4"}},[_c('label',{attrs:{"for":""}},[_vm._v("Tax type")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"item-value":"value","item-text":"text","hint":"Required tax","menu-props":{ bottom: true, offsetY: true },"rules":[function (v) { return !!v || 'Tax type is required'; }],"items":_vm.taxTypes,"hide-details":"auto","outlined":"","background-color":"#fff","dense":""},on:{"change":_vm.taxChange},model:{value:(_vm.product.tax_type_id),callback:function ($$v) {_vm.$set(_vm.product, "tax_type_id", $$v)},expression:"product.tax_type_id"}})],1),_c('v-col',{attrs:{"md":"4","lg":"4"}},[_c('label',{attrs:{"for":""}},[_vm._v("Price (Post Tax) ")]),_c('v-text-field',{staticClass:"q-text-field shadow-0",attrs:{"outlined":"","prefix":_vm.currencyCode,"required":"","rules":[
                  function (v) {
                    if (v >= 0) {
                      return true;
                    }
                    return 'Price is required';
                  } ],"hide-details":"auto","dense":""},on:{"change":function($event){return _vm.calculatePricingTaxVariation($event, 'post')}},model:{value:(_vm.product.total_price),callback:function ($$v) {_vm.$set(_vm.product, "total_price", $$v)},expression:"product.total_price"}})],1)],1)],1)]),_c('v-card-actions',{staticClass:"card-footer"},[_c('div',{staticClass:"d-flex w-full justify-end align-center mt-0"},[_c('v-btn',{staticClass:"ma-2 white--text blue-color",attrs:{"elevation":"0"},on:{"click":_vm.saveProduct}},[_vm._v("Update ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }