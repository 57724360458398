import Chart from "chart.js";
import Chartkick from "vue-chartkick";
import Vue from "vue";
import store from "../store";
// eslint-disable-next-line no-unused-vars
import ChartDataLabels from "chartjs-plugin-datalabels";
const totalizer = {
  id: "totalizer",

  beforeUpdate: (chart) => {
    let totals = {};
    let utmost = 0;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (chart.isDatasetVisible(datasetIndex)) {
        utmost = datasetIndex;
        dataset.data.forEach((value, index) => {
          totals[index] = (totals[index] || 0) + parseInt(value);
        });
      }
    });
    chart.data.datasets[0].currency=store.getters.currencyCode;
    chart.$totalizer = {
      totals: totals,
      utmost: utmost,
    };
  },
};
Chart.plugins.register(totalizer);
Chart.plugins.register(ChartDataLabels);

Vue.use(Chartkick.use(Chart));

export default Chart;
