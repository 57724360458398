import axios from "../../utils/request";

const getDefaultPaymentState = () => {
  return {
    paymentMethods: {
      status: false,
      data: [],
    },
    refundPaymentMethods: {
      status: false,
      data: [],
    },
    statuses: {
      status: false,
      data: [],
    },
    orderStatuses: {
      status: false,
      data: [],
    },
    cardTypes: {
      status: false,
      data: [],
    },
  };
};

const state = getDefaultPaymentState();

const getters = {
  getPaymentMethods: (state) => state.paymentMethods,
  getRefundPaymentMethods: (state) => state.refundPaymentMethods,
  getCardTypes: (state) => state.cardTypes,
  getStatuses: (state) => state.statuses,
  getOrderStatuses: (state) => state.orderStatuses,
};

const actions = {
  loadPaymentMethods(context, type) {
    return new Promise((resolve, reject) => {
      axios
        .get(`payment-methods/${type}`)
        .then((response) => {
          if (response && response.status == 200) {
            const data = response.data.data;
            context.commit("setPaymentMethods", data);
            resolve(data);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadStatuses(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`venues/general/statuses`)
        .then((response) => {
          if (response && response.status == 200) {
            const data = response.data.data;
            context.commit("setStatuses", data);
            resolve(data);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadOrderStatuses(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`venues/general/order-statuses`)
        .then((response) => {
          if (response && response.status == 200) {
            const data = response.data.data;
            context.commit("setOrderStatuses", data);
            resolve(data);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadCardTypes(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("general-types/card-type")
        .then((response) => {
          if (response && response.status == 200) {
            const data = response.data.data;
            context.commit("setCardTypes", data);
            resolve(data);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadRefundPaymentMethods(context) {
    return new Promise((resolve, reject) => {
      axios
          .get(`payment-methods/refund`)
          .then((response) => {
            if (response && response.status == 200) {
              const data = response.data.data;
              context.commit("setRefundPaymentMethods", data);
              resolve(data);
            }
            reject(response);
          })
          .catch((error) => {
            reject(error);
          });
    });
  },
};
const mutations = {
  resetPaymentState(state) {
    Object.assign(state, getDefaultPaymentState());
  },
  setPaymentMethods(state, data) {
    state.paymentMethods.status = true;
    state.paymentMethods.data = data;
  },
  setRefundPaymentMethods(state, data) {
    state.refundPaymentMethods.status = true;
    state.refundPaymentMethods.data = data;
  },
  setStatuses(state, data) {
    state.statuses.status = true;
    state.statuses.data = data;
  },
  setOrderStatuses(state, data) {
    state.orderStatuses.status = true;
    state.orderStatuses.data = data;
  },
  setCardTypes(state, data) {
    state.cardTypes.status = true;
    state.cardTypes.data = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
