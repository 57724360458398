import axios from "../../utils/request";

const getProductConfiguration = () => {
  return {
    documents: {},
    fieldDocuments: {},
    bookingFields: {},
    isConfigurationLoaded: {},
    configuration: {},
    membershipConfig: {},
    membershipTags: {},
    membershipCard: {},
    salesConfig: null,
    b2bConfig: {},
  };
};

const state = getProductConfiguration();

const getters = {
  getConfigurationByProduct: (state) => (productType) =>
    state.configuration[productType] ? state.configuration[productType] : [],
  configurationStatus: (state) => (productType) =>
    state.isConfigurationLoaded[productType]
      ? state.isConfigurationLoaded[productType]
      : false,
  getBookingFieldsByProduct: (state) => (productType) =>
    state.bookingFields[productType] ? state.bookingFields[productType] : [],
  getFieldDocumentsByProduct: (state) => (productType) =>
    state.fieldDocuments[productType] ? state.fieldDocuments[productType] : [],
  getMembershipCardByProduct: (state) => (productType) =>
    state.membershipCard[productType] ? state.membershipCard[productType] : [],
  getMembershipConfigByProduct: (state) => (productType) =>
    state.membershipConfig[productType]
      ? state.membershipConfig[productType]
      : [],
  getMembershipTagsByProduct: (state) =>
    state.membershipTags ? state.membershipTags : [],
  getSalesConfig: (state) => (state.salesConfig ? state.salesConfig : null),
  getB2bConfig: (state) => (state.b2bConfig ? state.b2bConfig : null),
};

const mutations = {
  resetConfiguration(state) {
    Object.assign(state, getProductConfiguration());
  },
  setDocuments: (state, data) => {
    state.documents[data.product_type] = data.data;
  },
  setFieldDocuments: (state, data) => {
    state.fieldDocuments[data.product_type] = data.data;
  },
  setFields: (state, data) => {
    state.bookingFields[data.product_type] = data.data;
  },
  setMembershipCard: (state, data) => {
    state.membershipCard[data.product_type] = data.data;
  },
  setMembershipConfig: (state, data) => {
    state.membershipConfig[data.product_type] = data.data;
  },
  setMembershipTags: (state, data) => {
    state.membershipTags = data.data;
  },
  setConfigurationStatus: (state, data) => {
    state.isConfigurationLoaded[data] = true;
  },
  setSalesConfig: (state, data) => {
    state.salesConfig = data.data;
  },
  setB2bConfig: (state, data) => {
    state.b2bConfig = data.data;
  },
};

const actions = {
  loadVenueSalesConfig(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/configurations/sales")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            context.commit("setSalesConfig", {
              data: data,
            });
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadConfigurations(context, productType) {
    return new Promise((resolve, reject) => {
      axios
        .get(`venues/configurations?product_type=${productType}`)
        .then((response) => {
          if (response && response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            if (
              data.venue_service_configuration != null &&
              data.venue_service_configuration.per_capacity != null
            ) {
              data.venue_service_configuration.per_capacity.toString();
            }

            context.commit("setConfiguration", {
              data: data.venue_service_configuration,
              product_type: productType,
            });

            context.commit("setFieldDocuments", {
              data: data.field_document_configurations,
              product_type: productType,
            });

            context.commit("setDocuments", {
              data: data.venue_service_documents,
              product_type: productType,
            });

            context.commit("setFields", {
              data: data.field_configurations,
              product_type: productType,
            });

            context.commit("setMembershipCard", {
              data: data.membership_card_configuration,
              product_type: productType,
            });

            context.commit("setMembershipConfig", {
              data: data.membership_configurations,
              product_type: productType,
            });

            context.commit("setMembershipTags", {
              data: data.membership_configurations.tags,
            });

            context.commit("setB2bConfig", {
              data: data.b2b_configurations,
              product_type: productType,
            });

            context.commit("setConfigurationStatus", productType);

            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
