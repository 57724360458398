import axios from "../../utils/request";

const getVenueState = () => {
  return {
    venue: {
      status: false,
      data: {},
    },
    services: {
      status: false,
      data: [],
    },
    kioskServices: {
      status: false,
      data: [],
    },
  };
};

const state = getVenueState();

const getters = {
  getVenueDetails: (state) => state.venue,
  getVenueServices: (state) => state.services,
  getSportsService: (state) => {
    // return state.services.data.filter((cat) => cat.service_type == "sports");
    return state.services.data;
  },
  getSocialServices: (state) => {
    // return state.services.data.filter((cat) => cat.service_type == "social");
    return state.services.data;
  },
  getKioskServices: (state) => {
    // return state.services.data.filter((cat) => cat.service_type == "sports");
    return state.kioskServices.data;
  },
};

const actions = {
  loadVenueProfileDetails(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/profile")
        .then((response) => {
          if (response && response.status == 200) {
            const data = response.data.data;
            const bankData = data.bank_account ? data.bank_account : {};
            const documents = {};
            data.documents.forEach((doc) => {
              if (doc.document_slug == process.env.VUE_APP_VENUE_LICENSE) {
                documents.license = doc.file_path;
                documents.original_file_name = doc.original_file_name;
              } else if (
                doc.document_slug == process.env.VUE_APP_VENUE_AGREEMENT
              ) {
                documents.agreement = doc.file_path;
              }
            });
            documents.trn = data.trn ? data.trn : null;
            let commitData = {
              address: {
                city_id: data.city_id,
                country_id: data.country_id,
                heat_map_image: data.heat_map_image,
                longitude: data.longitude,
                latitude: data.latitude,
                address: data.address,
              },
              bank_details: {
                account_number: bankData.account_number
                  ? bankData.account_number
                  : null,
                bank_name: bankData.bank_name ? bankData.bank_name : null,
                bank_branch: bankData.bank_branch ? bankData.bank_branch : null,
                iban: bankData.iban ? bankData.iban : null,
                account_holder: bankData.account_holder
                  ? bankData.account_holder
                  : null,
              },
              services: data.services,
              profile: {
                name: data.name,
                phone: data.phone,
                email: data.email,
                description: data.description,
                profile_image: data.profile_image,
                display_address: data.display_address
                  ? data.display_address
                  : data.address,
              },
              documents: documents,
              enable_smtp: data.enable_smtp,
              enable_azure_ad_sso: data.enable_azure_ad_sso,
              ad_config: data.ad_config,
              smtp_details: data.smtp_details,
              ai_sensy: data.ai_sensy,
              enable_split_payment: data.enable_split_payment,
              enable_qpoints: data.enable_qpoints,
            };
            context.commit("setVenueDetails", commitData);
            resolve(data);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadVenueServices(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/services")
        .then((response) => {
          if (response && response.status == 200) {
            const data = response.data.data;
            context.commit("setVenueServices", data);
            resolve(data);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadKioskVenueServices(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/kiosk")
        .then((response) => {
          if (response && response.status == 200) {
            const data = response.data.data;
            context.commit("setKioskVenueServices", data);
            resolve(data);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  resetVenueState(state) {
    Object.assign(state, getVenueState());
  },
  unsetVenueDetails: (state) => {
    state.venue.status = false;
  },
  setVenueDetails: (state, data) => {
    state.venue.status = true;
    state.venue.data = data;
  },
  setVenueServices: (state, data) => {
    state.services.status = true;
    state.services.data = data;
  },
  setKioskVenueServices: (state, data) => {
    state.kioskServices.status = true;
    state.kioskServices.data = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
