<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-if="button" readonly v-on="on" color="#062b48" dark block>{{
          dateFormatted
        }}</v-btn>
      <v-btn v-else-if="buttonAndText" readonly v-on="on" color="#062b48" dark text block style="font-weight: bolder; font-size: 1rem">{{
          dateFormatted
        }}<v-icon>mdi-chevron-down</v-icon> </v-btn>
      <v-btn
          v-else-if="icon"
          color="#062b48"
          dark
          readonly
          :class="className"
          v-on="on"
          large
      >
        <v-icon>mdi-calendar-cursor</v-icon>
      </v-btn>
      <v-text-field
          v-else
          :value="dateFormatted"
          :label="label"
          :dense="dense"
          :outlined="outlined"
          :solo="solo"
          readonly
          v-on="on"
          :rules="rules"
          :background-color=bgColor
          :class="className"
          :hide-details="hideDetails ? 'auto': false"

      >
        <template #prepend-inner>
          <slot name="prepend-inner"/>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
        v-if="isDateAllowedBool"
        v-bind="$attrs"
        v-on="$listeners"
        :min="button || backFill ? null : minDate"
        :max="maxDate ? maxDate : null"
        :value="value"
        :allowed-dates="isDateAllowed"
        @input="emitChange"
    ></v-date-picker>
    <v-date-picker
        v-else
        v-bind="$attrs"
        v-on="$listeners"
        :min="button || backFill ? null : minDate"
        :max="maxDate ? maxDate : null"
        :value="value"
        @input="emitChange"
    />
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  inheritAttrs: false,
  props: {
    value: { type: String, default: null },
    label: { type: String, default: "Select date" },
    dense: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    solo: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    button: { type: Boolean, default: false },
    buttonAndText: { type: Boolean, default: false },
    icon: { type: Boolean, default: false },
    fullHeight: { type: Boolean, default: false },
    backFill: { type: Boolean, default: false },
    dayName: { type: Boolean, default: false },
    minDate: { type: String, default: moment().format("YYYY-MM-DD") },
    maxDate: { type: String, default: null },
    className: { type: String, default: null },
    hideDetails:{type: Boolean , default:false},
    bgColor:{type: String , default:'#fff'},
    isDateAllowedBool: {type: Boolean , default:false},
    allowedDates: { type: Array, default: () => [] }
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    dateFormatted() {
      if (this.value == null) return null;
      if (this.dayName) {
        return moment(this.value, "YYYY-MM-DD").format("dddd, Do MMM YYYY");
      }
      return moment(this.value, "YYYY-MM-DD").format("Do MMM YYYY");
    },
  },
  methods: {
    emitChange(v) {
      this.$emit("input", v);
      this.menu = false;
    },
    isDateAllowed(date) {
      if (!date) return false; // If date is null or undefined, consider it disallowed

      // If date is a string, convert it to a Date object
      if (typeof date === 'string') {
        date = new Date(date);
      }

      // Check if the date is a valid Date object
      if (!(date instanceof Date && !isNaN(date))) return false;

      // Convert date to string in the format 'YYYY-MM-DD'
      const dateString = date.toISOString().split('T')[0];
      // Check if the date is included in the allowedDates array
      return this.allowedDates.includes(dateString);
    }
  },
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--large {
  height: 3rem !important;
}

</style>