<template>
  <v-app
    id="inspire"
    v-if="$route.name != 'Login' && this.$route.name != 'Root'"
  >
    <side-bar-navigation v-if="!$store.getters.KioskEnabled" />

    <!-- Content -->
    <div v-if="$store.getters.userRole == 'KIOSK'" class="KIOSK-BG"></div>
    <v-main class="background">
      <top-toolbar />
      <v-container class="main_container" :fluid="$store.getters.userRole != 'KIOSK'" >
        <v-layout>
          <v-fade-transition mode="out-in">
            <router-view />
          </v-fade-transition>
        </v-layout>
      </v-container>
    </v-main>

    <!-- Footer -->
    <bottom-footer />
  </v-app>
</template>

<script>
import SideBarNavigation from "./components/TheNavigationDrawer";
import TopToolbar from "./components/TheToolbar";
import BottomFooter from "./components/ThePageFooter";
export default {
  components: {
    SideBarNavigation,
    TopToolbar,
    BottomFooter,
  },
  mounted() {
    if (localStorage.getItem("kiosk") == "true") {
      this.kiosk = true;
      this.$store.dispatch("switchToKiosk", true);
      this.$router.push("/kiosk", () => {});
    }
  },
};
</script>
<style>
.KIOSK-BG{
    width: 100%;
    background-image: url('../assets/images/afh-bg-backup.png');
    position: absolute;
    height: 100%;
    background-size: cover;
    //background-position: center;
    //top: 0;
    //left: 0;
    //background-repeat: no-repeat;
}
.background{
  padding-top: 0 !important;
}
.main_container{
  max-width: 100%;
}
</style>