<script>
export default {
  name: "SvgIcon",
  props: {
    icon: {
      default: null
    },
    text: {
      default: null
    },
    imgStyles: {
      default: ""
    },
    component:{
      default:'div'
    }
  }
}
</script>

<template>
<component v-bind:is="component" :class="'d-flex align-center gap-x-1'" v-bind="$attrs" component="">
  <slot name="icon">
    <img :src="icon" :style="imgStyles" alt="">
  </slot>
  <span v-if="text">
    {{ text }}
  </span>
  <slot/>
</component>
</template>