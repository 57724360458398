<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      ref="menu"
      min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
          :value="dateFormatted"
          :label="label"
          readonly
          v-on="on"
          outlined
          :dense="dense"
          :rules="rules"
          :class="className"
          background-color="#fff"
      ></v-text-field>
    </template>
    <v-date-picker
        v-bind="$attrs"
        v-on="$listeners"
        :value="value"
        :min="min"
        @input="emitChange"
        multiple
        :hide-details="hideDetails"
    >
      <v-spacer></v-spacer>
      <v-btn
          text
          color="primary"
          @click="
          () => {
            $emit('change', []);
            $emit('input', []);
            $refs.menu.save();
          }
        "
      >Clear</v-btn
      >
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn
          text
          color="primary"
          @click="
          () => {
            $emit('change', values);
            $refs.menu.save();
          }
        "
      >OK</v-btn
      >
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  inheritAttrs: false,
  props: {
    value: { type: Array, default: () => [] },
    label: { type: String, default: "Select date" },
    dense: { type: Boolean, default: true },
    hideDetails: { type: String, default: '' },
    className: { type: String, default: '' },
    rules: { type: Array, default: () => [] },
    min: { type: String, default: moment().format("YYYY-MM-DD") },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    dateFormatted() {
      if (this.value.length == 0) return null;
      return this.value
          .map((date) => moment(date, "YYYY-MM-DD").format("Do MMM YYYY"))
          .join(",");
    },
  },
  methods: {
    emitChange(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style>
</style>