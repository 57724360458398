<template>
  <v-dialog v-model="CopyMoveModal" scrollable width="600px" persistent>
    <v-card class="card-rounded-bottom" style="min-height: 200px;">
      <v-card-text class="border-bottom">
        <div class="row pt-1">
          <div class="col-md-12">
            <div class="d-flex justify-space-between align-center mt-2">
              <SvgIcon class="text-2xl font-semibold"
                       text="Upload" style="color: black">
              </SvgIcon>
              <v-btn fab x-small class="shadow-0" @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
      <div class="mx-6 my-4">
        <nav aria-label="breadcrumb" class="breadcrumb-container">
          <span class="mr-1">Target Path:</span>
          <ol class="breadcrumb">
            <li v-for="(crumb, index) in breadCrumbs" :key="index" class="breadcrumb-item">
                    <span
                        @click="navigateFolders(crumb.id)"
                        class="breadcrumb-link"
                    >
                      {{ crumb.name }}
                    </span>
              <span v-if="index < breadCrumbs.length - 1"> > </span>
            </li>
          </ol>
        </nav>
      </div>
      <div class="md-card-content overflow-y-auto pt-0" style="max-height: 500px;">
        <v-simple-table class="table">
          <template v-slot:default>
            <thead>
              <tr class="rounded-sm tr-rounded tr-neon opacity-70">
                <th class="text-left black-text">Choose folder</th>
              </tr>
            </thead>
            <tbody>
            <tr v-if="tableRows.length < 1">
              <td colspan="5" class="text-center mt-4">
                <h3>No folders found</h3>
              </td>
            </tr>
            <tr class="text-left" v-for="(item,key) in tableRows" :key="key">
              <td @click="selectTarget(item)">
                <SvgIcon class="font-medium text-sm gap-x-2 breadcrumb-link" :text="item.name">
                  <template #icon>
                    <FolderIcon/>
                  </template>
                </SvgIcon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <v-card-actions class="card-footer">
        <div class="d-flex w-full justify-end align-center mt-0">
          <v-btn
              elevation="0"
              class="ma-2 white--text blue-color"
              @click="save"
          >Move here
          </v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import FolderIcon from "@/assets/images/misc/Folder.svg";

export default {
  name: "MediaCopyMoveModal",
  components: {FolderIcon, SvgIcon},
  props: {
    CopyMoveModal: {type: Boolean, default: false},
    isMove: {type: Boolean, default: false},
    copyMoveFileId: {type: Number, required: false},
  },
  watch: {
    CopyMoveModal(val) {
      if (val) {
        this.getTableRows();
      } else {
        this.tableRows = [];
      }
    }
  },
  data() {
    return {
      tableRows: [],
      targetId: null,
      targetName: null,
      breadCrumbs: [],
    }
  },
  methods: {
    selectTarget(item) {
      this.targetId = item.id;
      this.targetName = item.name;
      this.navigateFolders(this.targetId);
      this.$forceUpdate();
    },
    save() {
      let data = {
        is_move: this.isMove,
        target_id: this.targetId,
        file_id: this.copyMoveFileId,
      }
      this.$emit("saveCopyMove", data);
    },
    getTableRows() {
      let id = this.copyMoveFileId;
      this.showLoader('Loading data');
      this.$http.get(`venues/media-assets/get/folder/${id}`).then((response) => {
        if (response.status == 200) {
          this.tableRows = response.data.data

          this.breadCrumbs = [
            {
              name: 'Library',
              id: null,
            },
          ];

          if (response.data.bread_crumbs && Array.isArray(response.data.bread_crumbs)) {
            response.data.bread_crumbs.forEach(item => {
              if (item.name && item.id !== undefined) {
                this.breadCrumbs.push({
                  name: item.name,
                  id: item.id,
                });
              }
            });
          }

        }
      }).catch((error) => {
        this.errorChecker(error);
      }).finally(() => {
        this.hideLoader();
      });
    },
    navigateFolders(id = null) {
      this.targetId = id;
      this.targetName = id;
      let url = `venues/media-assets/get/folder-inner`;
      if (id) {
        url = `venues/media-assets/get/folder-inner/${id}`;
      }
      this.showLoader('Loading data');
      this.$http.get(url).then((response) => {
        if (response.status == 200) {
          this.tableRows = response.data.data
          this.breadCrumbs = [
            {
              name: 'Library',
              id: null,
            },
          ];

          if (response.data.bread_crumbs && Array.isArray(response.data.bread_crumbs)) {
            response.data.bread_crumbs.forEach(item => {
              if (item.name && item.id !== undefined) {
                this.breadCrumbs.push({
                  name: item.name,
                  id: item.id,
                });
              }
            });
          }

        }
      }).catch((error) => {
        this.errorChecker(error);
      }).finally(() => {
        this.hideLoader();
      });
    },
    close() {
      this.$emit("closeCopyMoveModal");
    },
  },
}
</script>
<style scoped>

.breadcrumb-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  flex-wrap: wrap;
}

.breadcrumb {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
}

.breadcrumb-link {
  cursor: pointer;
  color: black;
}

.breadcrumb-link:hover {
  text-decoration: underline;
  color: #00b1af;
}

.breadcrumb-item span {
  margin-right: 5px;
}

</style>