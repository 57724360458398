<template>
  <v-row no-gutters wrap>
    <v-col md="4">
      <v-select
          v-model="country"
          :items="countries"
          item-text="name"
          item-value="iso_2"
          label="Country"
          return-object
          :outlined="outlined"
          :dense="dense"
          :solo="solo"
          :menu-props="{ bottom: true, offsetY: true }"
          @change="upCountry()"
          background-color="#fff"
          class="text_field1"
      >
        <template v-slot:selection="{ attr, on, item }">
          <v-avatar v-bind="attr" rounded size="18" color="white" v-on="on">
            <view-image
                :image="`flags/${item.iso_2}.png`"
                ae
                :contain="false"
            ></view-image>
          </v-avatar>
          <span class="ml-2 text-bold">{{ item.iso_2 }}</span>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-avatar
              rounded
              color="white"
              class="text-h5 font-weight-light white--text"
          >
            <view-image
                :image="`flags/${item.iso_2}.png`"
                :contain="false"
            ></view-image>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
            >{{ item.iso_2 }} -+{{ item.code }}</v-list-item-title
            >
            <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>
    </v-col>
    <v-col md="8">
      <v-text-field
          :value="value"
          :label="`Mobile${required ? '*' : ''}`"
          :outlined="outlined"
          :solo="solo"
          :dense="dense"

          v-bind="$attrs"
          class="text_field2"
          v-on="$listeners"

          background-color="#fff"
          @input="(v) => $emit('input', v)"
          @change="updateMobileWithCode"
          @blur="updateMobileWithCode"
          :rules="mobileRules()"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import {
  parsePhoneNumberFromString,
  // parsePhoneNumber,
} from "libphonenumber-js";
export default {
  inheritAttrs: false,
  props: {
    value: { type: String },
    dense: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    solo: { type: Boolean,default:false},
    refreshCountry: { type: Boolean, default: false },
    required: { type: Boolean, default: true },
  },
  data() {
    return {
      country: {},
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val && val.charAt(0) == "+") {
          this.changeCountry();
        } else {
          this.initialize();
        }
      },
    },
    refreshCountry: {
      immediate: true,
      handler(val) {
        console.log(val);
        this.changeCountry();
      },
    },
  },
  mounted() {
    this.initialize();
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },

  },
  methods: {
    initialize() {
      if (this.$store.getters.getCountries.status == false) {
        this.$store.dispatch("loadCountries").then((response) => {
          if (response) {
            if (!this.value) {
              this.country = this.countries.find(
                  (item) => item.iso_2 == this.$store.getters.countryIso_2
              );
            }
          }
        });
      } else {
        this.country = this.countries.find(
            (item) => item.iso_2 == this.$store.getters.countryIso_2
        );
      }
    },

    updateMobileWithCode() {
      if (this.value) {
        let countryCode =
            this.country && this.country.iso_2 ? this.country.iso_2 : "AE";
        let number = parsePhoneNumberFromString(this.value, countryCode);
        if (number != null && number.isValid()) {
          this.$emit("input", number.number);
          this.$emit("validated", number.number);
        }
      }
    },
    changeCountry() {
      if (this.value) {
        let countryCode = this.country && this.country.iso_2 ? this.country.iso_2 : "AE";
        let mobile = parsePhoneNumberFromString(this.value);
        if(typeof mobile != "undefined"){
          if (countryCode != mobile.country) {
            if (this.countries.length) {
              this.country = this.countries.find(
                  (item) => item.iso_2 == mobile.country
              );
            } else {
              setTimeout(() => {
                this.changeCountry();
              }, 1000);
            }
          } else {
            if (this.$store.getters.getCountries.status == false) {
              this.country = this.countries.find(
                  (item) => item.iso_2 == mobile.country
              );
            } else {
              this.$store.dispatch("loadCountries").then((response) => {
                if (response) {
                  this.country = this.countries.find(
                      (item) => item.iso_2 == mobile.country
                  );
                  this.$forceUpdate();
                }
              });
            }
          }
        }
      }
    },
    mobileRules() {
      const rules = [];
      let rule = null;
      if (this.required) {
        rule = (v) => !!v || "Mobile number is required";
        rules.push(rule);
      }
      if (this.value) {
        let countryCode = this.country && this.country.iso_2 ? this.country.iso_2 : "AE";
        let number = parsePhoneNumberFromString(this.value, countryCode);
        if (number) {
          if (number.country != countryCode) {
            rules.push(`Number is not valid for ${this.country?.name}`);
          }
          if (number.isValid() == false) {
            rules.push(`Not a valid phone number`);
          }
        }else{
          rules.push(`Valid number is required`);
        }
      }
      return rules;
    },
    upCountry(){
      if(this.country && this.country.code != "971"){
        let newVal = "+"+this.country.code;
        this.$emit('input', newVal)
        this.$forceUpdate();
      }else{
        this.$emit('input', "");
      }
    }
  },
};
</script>

<style>
</style>