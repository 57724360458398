var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{ref:"datemenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:_vm.className,attrs:{"value":_vm.dateFormatted,"label":_vm.label,"readonly":"","solo":"","dense":"","flat":_vm.flat,"hide-details":_vm.hideDetails}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datemenu),callback:function ($$v) {_vm.datemenu=$$v},expression:"datemenu"}},[_c('v-date-picker',{attrs:{"value":_vm.value,"range":_vm.range,"no-title":"","scrollable":""},on:{"input":_vm.emitChange}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function () {
          _vm.$emit('input', null);
          _vm.$emit('change');
          _vm.$refs.datemenu.save();
        }}},[_vm._v("Clear")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.datemenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function () {
          _vm.$emit('change');
          _vm.$refs.datemenu.save();
        }}},[_vm._v("OK")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }