import Vue from "vue";
import ImageUpload from "@/components/Image/ImageUpload";
import ViewImage from "@/components/Image/ViewImage";
import DateMenu from "@/components/Fields/DateMenu";
import DateField from "@/components/Fields/DateField";
import MultiDateField from "@/components/Fields/MultiDateField";
import DatePeriod from "@/components/Fields/DatePeriod";
import DateOfBirth from "@/components/Fields/DateOfBirth";
import MobileField from "@/components/Fields/MobileField";
import VMobileSearch from "@/components/Customer/VMobileSearch";
import VNameSearch from "@/components/Customer/VNameSearch";
import VNameField from "@/components/Customer/VNameField";
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import CardDataButton from "@/components/EmiratesId/EmiratesIdReader";
import IdCardReaderButton from "@/components/EmiratesId/IdCardReader";
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component("image-upload", ImageUpload);
Vue.component("view-image", ViewImage);
Vue.component("date-menu", DateMenu);
Vue.component("date-field", DateField);
Vue.component("multi-date-field", MultiDateField);
Vue.component("date-period", DatePeriod);
Vue.component("date-of-birth", DateOfBirth);
Vue.component("mobile-field", MobileField);
Vue.component("v-mobile-search", VMobileSearch);
Vue.component("v-name-search", VNameSearch);
Vue.component("v-name-field", VNameField);
Vue.component("confirm-model", ConfirmModel);
Vue.component("card-data-button", CardDataButton);
Vue.component("card-reader-button", IdCardReaderButton);
Vue.component('qr-code', VueQRCodeComponent)

export default Vue.component;