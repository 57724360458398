<template>
  <v-app>
    <dashBoard-view
      v-if="$route.name != 'Login' && this.$route.name != 'Root'"
    ></dashBoard-view>
    <default-view v-else></default-view>
    <loader-overlay />
    <notifier />
  </v-app>
</template>

<script>
import DashBoardView from "./Main";
import DefaultView from "./Default";
import Notifier from "../components/Notification/Notifier";
import LoaderOverlay from "../components/Notification/LoaderOverlay";
export default {
  components: {
    DashBoardView,
    DefaultView,
    Notifier,
    LoaderOverlay,
  },
};
</script>

<style>
</style>