import axios from "@/utils/request";

const state = {
  status: "",
  token: localStorage.getItem("token") || "",
  user: JSON.parse(localStorage.getItem("user")) || {},
  venue: JSON.parse(localStorage.getItem("venue")) || {},
  kiosk: false,
  country: JSON.parse(localStorage.getItem("country")) || {},
  svid: parseInt(localStorage.getItem("svid")) || null, //shop venue id
};

const getters = {
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  token: (state) => state.token,
  userInfo: (state) => state.user,
  userRole: (state) => state.user.role,
  userRoles: (state) => state.user.roles,
  userVenues: (state) => (state.user.venues ? state.user.venues : []),
  venueInfo: (state) => state.venue,
  KioskEnabled: (state) => state.kiosk,
  countryIso_2: (state) => (state.country.iso_2 ? state.country.iso_2 : "AE"),
  currencyCode: (state) => state.country.currency_code ? state.country.currency_code : "AED",
  svid: (state) => state.svid?state.svid: null,
};

const actionHandler = (resp, commit, resolve) => {
  if (resp.status == 200 && resp.data.status == true) {
    const response = resp.data.data;
    const token = "Bearer " + response.token;
    const user = {
      username: response.username,
      email: response.email,
      first_name: response.first_name,
      last_name: response.last_name,
      role: response.role ? response.role.title : null,
      role_id: response.current_role_id,
      venue_id: response.current_venue_id,
      mobile: response.mobile,
      roles: response.roles,
      venues: response.venues,
      image_path: response.profile_image != null ? response.profile_image : null,
      user_id: response.id,
    };
    const venue = {
      name: response.venue.name,
      code: response.venue.code,
      address: response.venue.address,
      phone: response.venue.phone,
      email: response.venue.email,
      logo:
        response.venue.profile_image != null
          ? response.venue.profile_image
          : null,
      enable_dct_erp: response.venue.enable_dct_erp,
      enable_open_dated_tickets: response.venue.enable_open_dated_tickets,
      dct_erp_configuration: response.venue.dct_erp_configuration,
      trn: response.venue.trn,
      sub_venues: response.venue.sub_venues,
      enable_cid: response.venue.enable_cid,
      enable_icp: response.venue.enable_icp,
    };
    const country = response.venue.country;
    const permissions = response.permissions;
    const selectedShopVenueId = response.current_venue_id;
    if (permissions.length == 0) {
      commit("SHOW_NOTIFICATION", {
        text: "No permissions",
        type: "error",
        timeout: 10000,
      });
    }

    // Remove Current Values From Local Storage
    localStorage.removeItem("token");
    localStorage.removeItem("venue");
    localStorage.removeItem("user");
    localStorage.removeItem("svid");
    //Set New Values to Local Storage

    // Default header details
    axios.defaults.headers.common["Authorization"] = token;

    commit("auth_success", {
      token: token,
      user: user,
      venue: venue,
      selectedShopVenueId: selectedShopVenueId,
    });
    commit("setPermissions", permissions);
    commit("setCountryDetails", country);

    resolve(resp);
  }
};

const actions = {
  login({ commit }, user) {
    commit("auth_request");
    return new Promise((resolve, reject) => {
      axios
        .post("auth/login", user)
        .then((resp) => {
          actionHandler(resp, commit, resolve);
          reject(resp);
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.removeItem("token");
          reject(err);
        });
    });
  },
  loginAD({ commit }, user) {
    commit("auth_request");
    return new Promise((resolve, reject) => {
      axios
        .post("auth/login-sso", user)
        .then((resp) => {
          actionHandler(resp, commit, resolve);
          reject(resp);
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.removeItem("token");
          reject(err);
        });
    });
  },
  switchRole({ commit }, role) {
    commit("auth_request");
    return new Promise((resolve, reject) => {
      axios
        .post("auth/switch-role", role)
        .then((resp) => {
          actionHandler(resp, commit, resolve);
          reject(resp);
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.removeItem("token");
          reject(err);
        });
    });
  },
  switchSVID({commit},data){
    return new Promise((resolve) => {
      localStorage.setItem("svid", data);
      commit("set_svid", data);
      resolve(data);
    });
  },
  switchVenue({ commit }, role) {
    commit("auth_request");
    return new Promise((resolve, reject) => {
      axios
        .post("auth/switch-venue", role)
        .then((resp) => {
          actionHandler(resp, commit, resolve);
          reject(resp);
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.removeItem("token");
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("auth/logout")
        .then((resp) => {
          if (resp.status == 200) {
            localStorage.removeItem("token");
            localStorage.removeItem("venue");
            localStorage.removeItem("user");
            localStorage.removeItem("venue_image_data");
            delete axios.defaults.headers.common["Authorization"];
            commit("resetGeneralState");
            commit("resetFacilityState");
            commit("resetOrderState");
            commit("resetPaymentState");
            commit("resetPermission");
            commit("resetFacilitySchedule");
            commit("resetVenueState");
            commit("resetConfiguration");
            resolve(resp);
          }
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          localStorage.removeItem("venue");
          localStorage.removeItem("user");
          localStorage.removeItem("venue_image_data");
          delete axios.defaults.headers.common["Authorization"];
          commit("resetGeneralState");
          commit("resetFacilityState");
          commit("resetOrderState");
          commit("resetPaymentState");
          commit("resetPermission");
          commit("resetFacilitySchedule");
          commit("resetVenueState");
          commit("resetConfiguration");
          resolve(err);
          reject(err);
        });
    });
  },
  switchToKiosk({ commit }, kiosk) {
    return new Promise((resolve) => {
      localStorage.setItem("kiosk", kiosk);
      commit("set_kiosk", kiosk)
      resolve(kiosk);
    });
  },
};

const mutations = {
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, data) {
    localStorage.setItem("token", data.token);
    localStorage.setItem("venue", JSON.stringify(data.venue));
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("svid", data.selectedShopVenueId);
    state.user = data.user;
    state.venue = data.venue;
    state.status = "success";
    state.token = data.token;
    state.svid = data.selectedShopVenueId;
  },
  update_user(state, data) {
    state.user = data.user;
  },
  auth_error(state) {
    state.status = "error";
  },
  logout(state) {
    state.status = "";
    state.token = "";
    state.venue = {};
    state.user = {};
  },
  update_user_info(state, data) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.first_name = data.first_name;
    user.last_name = data.last_name;
    user.username = data.username;
    user.email = data.email;
    user.name = data.name;
    user.mobile = data.mobile;
    user.image_path = data.image_path;
    localStorage.setItem("user", JSON.stringify(user));
    state.user = user;
  },
  set_kiosk(state, data) {
    state.kiosk = data;
  },
  setCountryDetails(state, data) {
    localStorage.setItem("country", JSON.stringify(data));
    state.country = data;
  },
  set_svid(state, data) {
    state.svid = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
