<template>
  <!-- <v-btn class="white--text blue-color mr-1" @click="readPublicData">
    {{ label }}
  </v-btn> -->
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :class="className"
          :dark="dark"
          :depressed="depressed"
          :plain="plain"
          outlined
          v-bind="attrs"
          v-on="on"
          >{{ label }}</v-btn
        >
      </template>
      <v-list>
        <v-list-item @click="readPublicData('doc')">
          <v-list-item-icon class="mr-1">
            <v-icon>mdi-scanner</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Scan Document</v-list-item-title>
        </v-list-item>
        <v-list-item @click="readPublicData('chip')">
          <v-list-item-icon class="mr-1">
            <v-icon>mdi-sim</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Read Chip</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";
const jsonp = require("jsonp");
import frontSideJson from "./Priyanka_EmiratesID_FrontSide.json";
import backSideJson from "./Priyanka_EmiratesID_BackSide.json";
import priyankaPassportJson from "./Priyanka_Passport.json";
import brianPassportJson from "./Brian_Passport.json";
export default {
  components: {},
  props: {
    label: { type: String, default: "Use ID" },
    className: { type: String, default: "white--text blue-color mr-1" },
    docType: { type: String, default: null },
    dark: { type: Boolean, default: true },
    outlined: { type: Boolean, default: false },
    plain: { type: Boolean, default: false },
    depressed: { type: Boolean, default: false },
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        name: "",
        dob: "",
        gender: "",
        nationality: "",
        nationality_code: "",
        id_number: "",
        card_number: "",
        mobile: "",
        email: "",
        passport_number: "",
        isEmiratesIdCheck: true,
      },
    };
  },
  watch: {},
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }
  },

  methods: {
    readPublicData0() {
      // this.showLoader("Loading");
      let jsonData = "";
      let url = "";
      if (this.docType != null && this.docType == "doc") {
        jsonData = JSON.parse(priyankaPassportJson);
        // url += `http://127.0.0.1:8093/samsotechscannerservice/scan`;
        // url += "https://en.wikipedia.org/api/rest_v1/page/";
      } else if (this.docType != null && this.docType == "chip") {
        jsonData = JSON.parse(frontSideJson);
        // url += `http://127.0.0.1:8093/samsotechscannerservice/readchip`;
      } else {
        jsonData = JSON.parse(frontSideJson);
        jsonData = JSON.parse(priyankaPassportJson);
        jsonData = JSON.parse(backSideJson);
        jsonData = JSON.parse(brianPassportJson);
        console.log(url);
        this.showError("Document type not defined");
        return;
      }
      try {
        // const response = await axios.get(url);
        // const response = await axios({
        //   method: "get",
        //   headers: { "Content-Type": "json" },
        //   url: url,
        //   responseType: "json",
        // });
        // jsonData = JSON.parse(jsonData);
        if (jsonData.result) {
          // this.user = jsonData;
          /** Email set*/
          if (jsonData.Email) {
            this.user.email = jsonData.Email.trim();
          }
          /** Phone number set*/
          if (jsonData.mobileNumber) {
            this.user.mobile = jsonData.mobileNumber.trim();
          }

          let first_name = "";
          let last_name = "";
          /** First Name set*/
          if (jsonData.firstName) {
            let name = jsonData.firstName.replace(/[^a-zA-Z ]/g, "").trim();
            [first_name, ...last_name] = name.split(" ");
            this.user.first_name = first_name;
          }
          /** Middle name set as last name */
          if (jsonData.middleName) {
            let middleName = jsonData.middleName
              .replace(/[^a-zA-Z ]/g, "")
              .trim();
            last_name += last_name != "" ? " " + middleName : middleName;
          }
          /** last name set and concat with middle name if exist*/
          if (jsonData.lastName) {
            let lastName = jsonData.lastName.replace(/[^a-zA-Z ]/g, "").trim();
            last_name += last_name != "" ? " " + lastName : lastName;
          }
          /** Last Name assign to user emit object */
          if (last_name != "") {
            this.user.last_name = last_name;
            this.user.name = first_name + " " + last_name;
          } else {
            this.user.name = first_name;
          }
          /** Gender set*/
          if (
            jsonData.gender.toLowerCase() == "m" ||
            jsonData.gender.toLowerCase() == "male"
          ) {
            this.user.gender = "Male";
          } else if (
            jsonData.gender.toLowerCase() == "f" ||
            jsonData.gender.toLowerCase() == "female"
          ) {
            this.user.gender = "Female";
          }
          /** Country code set */
          let country = null;
          if (jsonData.nationality_code2) {
            country = this.countries.find(
              (item) => item.iso_2 == jsonData.nationality_code2
            );
          }
          if (country) {
            this.user.country_id = country.id;
          }
          /** date of birth set */
          if (jsonData.dateOfBirth) {
            this.user.dob = moment(jsonData.dateOfBirth, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            );
          }
          /** ID Proof Type Set */
          let idProofTypeObj = null;
          if (jsonData.idType) {
            let proofType = "";
            if (jsonData.idType == "P") {
              proofType = "Passport";
            } else if (jsonData.idType == "NID") {
              proofType = "National ID";
            }
            idProofTypeObj = this.idProofTypes.find(
              (item) => item.name == proofType
            );
            // console.log(idProofTypeObj);
            if (idProofTypeObj) {
              this.user.id_proof_type_id = idProofTypeObj.id;
              this.user.id_proof_number = jsonData.documentNumber;
            }
          }
          /** ID Proof image */
          if (jsonData.fullImage) {
            var blob = this.DataURIToBlob(
              "data:image/png;base64," + jsonData.fullImage
            );
            var file = new File([blob], first_name + ".png", {
              type: "image/png",
            });
            this.user.id_proof = file;
          }
          this.user.isEmiratesIdCheck = true;
          this.$emit("data", this.user);
        } else {
          this.showError("Result not found");
        }
      } catch (err) {
        if (err.response) {
          this.showError(err);
          // client received an error response (5xx, 4xx)
          console.log("Server Error:", err);
        } else if (err.request) {
          this.showError(err);
          // client never received a response, or request never left
          console.log("Network Error:", err);
        } else {
          this.showError(err);
          console.log("Client Error:", err);
        }
      }
    },
    readPublicData(docType = null) {
      this.showLoader("Loading");
      let url = "";
      if (docType != null && docType == "doc") {
        url = "http://127.0.0.1:8093/samsotechscannerservice/scan";
      } else if (docType != null && docType == "chip") {
        url = "http://127.0.0.1:8093/samsotechscannerservice/readchip";
      } else {
        // console.log(url);
        this.showError("Document type not defined");
        this.hideLoader();
        return;
      }
      jsonp(url, { timeout: 12000 }, (err, data) => {
        if (err) {
          this.hideLoader();
          console.error(err.message);
          this.showError("Scanner is not working");
          return;
        } else {
          this.hideLoader();
          // console.log(data);
          if (data == "") {
            this.showError("Result not found");
            return;
          }
          let jsonData = JSON.parse(data);
          if (jsonData.result) {
            // console.log(jsonData);
            // this.user = jsonData;
            /** Email set*/
            if (jsonData.Email) {
              this.user.email = jsonData.Email.trim();
            }
            /** Phone number set*/
            if (jsonData.mobileNumber) {
              this.user.mobile = "+" + jsonData.mobileNumber.trim();
            }

            let first_name = "";
            let last_name = "";
            /** Full Name set*/
            if (jsonData.fullName) {
              let name = jsonData.fullName.replace(/[^a-zA-Z ]/g, "").trim();
              name = name.replace(/\s+/g, " ").trim();
              [first_name, ...last_name] = name.split(" ");
              this.user.first_name = first_name.trim();
              last_name = last_name.join(" ");
              this.user.last_name = last_name.trim();
            }
            /** First Name set*/
            if (jsonData.firstName) {
              let name = jsonData.firstName.replace(/[^a-zA-Z ]/g, "").trim();
              name = name.replace(/\s+/g, " ").trim();
              [first_name, ...last_name] = name.split(" ");
              this.user.first_name = first_name;
              last_name = last_name.join(" ");
              this.user.last_name = last_name.trim();
            }
            /** Middle name set as last name */
            if (jsonData.middleName) {
              let middleName = jsonData.middleName
                .replace(/[^a-zA-Z ]/g, "")
                .trim();
              middleName = middleName.replace(/\s+/g, " ").trim();
              last_name += last_name != "" ? " " + middleName : middleName;
            }
            /** last name set and concat with middle name if exist*/
            if (jsonData.lastName) {
              let lastName = jsonData.lastName
                .replace(/[^a-zA-Z ]/g, "")
                .trim();
              lastName = lastName.replace(/\s+/g, " ").trim();
              last_name += last_name != "" ? " " + lastName : lastName;
            }
            /** Last Name assign to user emit object */
            if (last_name != "") {
              this.user.last_name = last_name;
              this.user.name = first_name + " " + last_name;
            } else {
              if (first_name != "") {
                this.user.name = first_name;
              }
            }

            /** Gender set*/
            if (
              jsonData.gender &&
              (jsonData.gender.toLowerCase() == "m" ||
                jsonData.gender.toLowerCase() == "male")
            ) {
              this.user.gender = "Male";
            } else if (
              jsonData.gender &&
              (jsonData.gender.toLowerCase() == "f" ||
                jsonData.gender.toLowerCase() == "female")
            ) {
              this.user.gender = "Female";
            }
            /** Country code set */
            let country = null;
            if (jsonData.nationality_code2) {
              country = this.countries.find(
                (item) => item.iso_2 == jsonData.nationality_code2
              );
            }
            if (country) {
              this.user.country_id = country.id;
            }
            /** date of birth set */
            if (jsonData.dateOfBirth) {
              this.user.dob = moment(jsonData.dateOfBirth, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              );
            }
            /** ID Proof Type Set */
            let idProofTypeObj = null;
            if (jsonData.idType) {
              let proofType = "";
              if (jsonData.idType == "P") {
                proofType = "Passport";
              } else if (jsonData.idType == "NID") {
                proofType = "National ID";
              }
              idProofTypeObj = this.idProofTypes.find(
                (item) => item.name == proofType
              );
              // console.log(idProofTypeObj);
              if (idProofTypeObj) {
                this.user.id_proof_type_id = idProofTypeObj.id;
                this.user.id_proof_number = jsonData.documentNumber;
              }
            }
            /** ID Proof image */
            if (jsonData.fullImage) {
              // console.log(jsonData.fullImage);
              var blob = this.DataURIToBlob(
                "data:image/png;base64," + jsonData.fullImage
              );
              var file = new File([blob], first_name + ".png", {
                type: "image/png",
              });
              this.user.id_proof = file;
            }
            this.user.isEmiratesIdCheck = true;
            this.$emit("data", this.user);
          } else {
            this.showError("Result not found");
          }
          // console.log("json data", jsonData);
          // if (jsonData.result) {
          //   this.user = jsonData;
          //   this.$emit("data", this.user);
          //   console.log(jsonData);
          // } else {
          //   this.showError("Result not found");
          // }
        }
      });
    },
    checkDeviceReady() {
      let url = "http://127.0.0.1:8093/SamsotechScannerService/isDeviceReady";
      this.$http
        .get(url)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          this.showError(error);
          return false;
        });
    },
    DataURIToBlob(dataURI = "") {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    },
  },
};
</script>

<style>
</style>