import axios from "../../utils/request";

const getDefaultPermissionState = () => {
  return {
    permissionLoaded: false,
    permissions: [],
  };
};

const state = getDefaultPermissionState();

const getters = {
  isPermissionsLoaded: (state) => state.permissionLoaded,
  allPermissions: (state) => state.permissions,
  getPermissionBySlug: (state) => (slug) => {
    return state.permissions.length != 0
      ? state.permissions.find((permission) => permission.slug == slug)
      : null;
  },
  checkReadPermission: (state) => (slug) => {
    if (state.permissions.length != 0) {
      let module = state.permissions.find(
        (permission) => permission.slug == slug
      );
      // console.log(module);
      // console.log(slug);
      return typeof module != "undefined" && module.allow_read == 1
        ? true
        : false;
    }
    return false;
  },
  checkWritePermission: (state) => (slug) => {
    if (state.permissions.length != 0) {
      let module = state.permissions.find(
        (permission) => permission.slug == slug
      );

      return typeof module != "undefined" && module.allow_write == 1
        ? true
        : false;
    }
    return false;
  },
  checkDeletePermission: (state) => (slug) => {
    if (state.permissions.length != 0) {
      let module = state.permissions.find(
        (permission) => permission.slug == slug
      );

      return typeof module != "undefined" && module.allow_delete == 1
        ? true
        : false;
    }
    return false;
  },
  checkExportPermission: (state) => (slug) => {
    if (state.permissions.length != 0) {
      let module = state.permissions.find(
        (permission) => permission.slug == slug
      );

      return typeof module != "undefined" && module.allow_export == 1
        ? true
        : false;
    }
    return false;
  },
  checkBackfillPermission: (state) => (slug) => {
    if (state.permissions.length != 0) {
      let module = state.permissions.find(
        (permission) => permission.slug == slug
      );

      return typeof module != "undefined" && module.allow_backfill == 1
        ? true
        : false;
    }
    return false;
  },
  getLoggedInUserID: (state) => {
    return state.permissions[0].venue_user_id;
  },
};

const actions = {
  loadPermissions({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("venues/users/permissions")
        .then((resp) => {
          if (resp.status == 200 && resp.data.status == true) {
            commit("setPermissions", resp.data.data);
            // console.log(resp.data.data);
            resolve(resp);
          }
          reject(resp);
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.removeItem("token");
          reject(err);
        });
    });
  },
};

const mutations = {
  setPermissions(state, data) {
    state.permissionLoaded = true;
    state.permissions = data;
  },
  resetPermission(state) {
    Object.assign(state, getDefaultPermissionState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
