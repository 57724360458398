var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":"","wrap":""}},[_c('v-col',{attrs:{"md":_vm.dialCodeGridSize}},[_c('v-select',{class:_vm.className1,attrs:{"items":_vm.countries,"item-text":"name","item-value":"iso_2","label":_vm.showLabel?'Country ':'',"return-object":"","outlined":"","menu-props":{ bottom: true, offsetY: true },"background-color":_vm.backgroundColor,"dense":_vm.dense,"hide-details":_vm.hideDetails},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
return [(_vm.showFlag)?_c('v-avatar',_vm._g(_vm._b({attrs:{"rounded":"","size":"18","color":"white"}},'v-avatar',attr,false),on),[_c('view-image',{attrs:{"image":("flags/" + (item.iso_2) + ".png"),"contain":false}})],1):_vm._e(),(_vm.showFlag)?_c('span',{staticClass:"ml-2 text-bold"},[_vm._v(_vm._s(item.iso_2))]):_c('span',{staticClass:"ml-2 text-bold mobile_country_code"},[_vm._v("+"+_vm._s(item.code))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"rounded":"","color":"white"}},[_c('view-image',{attrs:{"image":("flags/" + (item.iso_2) + ".png"),"contain":false}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.iso_2)+" -+"+_vm._s(item.code))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('v-col',{attrs:{"md":12-_vm.dialCodeGridSize}},[_c('v-autocomplete',{ref:"mobileField",class:_vm.className2,attrs:{"value":_vm.value,"items":_vm.entries,"loading":_vm.isSearchLoading,"search-input":_vm.search,"item-text":"mobile","item-value":"id","label":_vm.showLabel?'Mobile ':'',"placeholder":"Mobile..","auto-select-first":"","return-object":"","outlined":"","required":"","autocomplete":"cc-mob","background-color":_vm.backgroundColor,"rules":_vm.mobileRules(),"dense":_vm.dense,"hide-details":_vm.hideDetails},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":[function($event){_vm.search=$event},_vm.debouncedInputChange],"change":_vm.changeCustomerData,"blur":_vm.entryShift,"select":_vm.debouncedInputChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item.profile_image)?_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"rounded":"","color":"teal"}},[_c('view-image',{attrs:{"image":item.profile_image,"contain":false}})],1):(item.first_name)?_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"rounded":"","color":"teal"}},[_vm._v(" "+_vm._s(item.first_name.charAt(0))+" ")]):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.mobile))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }