import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import permission from "./modules/permission";
import notifier from "./modules/notifier";
import event from "./modules/event";
import loader from "./modules/loader";
import general from "./modules/general";
import b2b from "./modules/b2b";
import azureAD from "./modules/azureAD";
import venue from "./modules/venue";
import payment from "./modules/payment";
import order from "./modules/order";
import facility from "./modules/facility";
import schedule from "./modules/schedule";
import configuration from "./modules/configuration";
import repeatBooking from "./modules/repeatBooking";
import salesConfiguration from "./modules/salesConfiguration";
import cart from "./modules/cart";
// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    auth,
    permission,
    notifier,
    loader,
    general,
    venue,
    payment,
    order,
    event,
    facility,
    schedule,
    b2b,
    azureAD,
    configuration,
    repeatBooking,
    salesConfiguration,
    cart,
  },
});
