<template>

    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-card>
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold"
                         :text="title" style="color: black">
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="closeDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="mt-4">

            <v-img v-if="isImage" :src="getPath(path)" max-height="1000px" contain></v-img>
            <v-responsive v-else-if="isPdf" max-height="1000px" min-height="500px">
              <embed :src="getPath(path)" type="application/pdf" style="width: 100%; height: 100%;" />
            </v-responsive>
            <v-card-text v-else>
              <div class="d-flex justify-center align-center mt-2">
                <p>No preview available for this file type.</p>
              </div>
            </v-card-text>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

</template>

<script>

import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {SvgIcon},

  props: {
    extension: {
      type: String,
      default: ''
    },
    path: String,
    title: {
      type: String,
      default: 'File Preview'
    },
    dialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
    };
  },
  methods:{
    getPath(path){
      return this.s3BucketURL + path;
    },
    closeDialog() {
      this.$emit("closePreview");
    }
  },
  computed: {
    isImage() {
      return ['jpg', 'jpeg', 'png', 'gif','svg'].includes(this.extension.toLowerCase());
    },
    isPdf() {
      return this.extension.toLowerCase() === 'pdf';
    },
  },
};
</script>

<style scoped>
/* Add custom styles if needed */
</style>
