import { render, staticRenderFns } from "./FilePreview.vue?vue&type=template&id=61df8f70&scoped=true&"
import script from "./FilePreview.vue?vue&type=script&lang=js&"
export * from "./FilePreview.vue?vue&type=script&lang=js&"
import style0 from "./FilePreview.vue?vue&type=style&index=0&id=61df8f70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61df8f70",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VImg,VResponsive,VTooltip})
