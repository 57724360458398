<template>
  <v-dialog v-model="logoutDialoge" scrollable persistent max-width="390">
    <v-card>
      <v-card-title class="headline"> Confirm with password </v-card-title>
      <v-card-text class="form_bg">
        <v-form method="post">
          <v-row>
            <v-col cols="12" class="mt-3">
              <v-text-field
                outlined
                background-color="#fff"
                v-model="user.username"
                label="Username"
                readonly
              >
              </v-text-field>

              <v-text-field
                v-model="user.password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="show ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                autocomplete="new-password"
                outlined
                background-color="#fff"
                hint="At least 8 characters"
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2 white--text blue-color"
          text
          @click="$emit('close', true)"
          >Close</v-btn
        >
        <v-btn class="ma-2 white--text teal-color" text @click="logOut">{{
          label
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    logoutDialoge: { type: Boolean, default: false },
    label: { type: String, default: "Logout" },
  },
  data() {
    return {
      show: false,
      user: { username: this.$store.getters.userInfo.username, password: null },
      rules: {
        required: (value) => !!value || "Password Required.",
      },
    };
  },
  created() {},
  methods: {
    logOut() {
      this.$http
        .post("venues/kiosk/dashboard-switch", this.user)
        .then((res) => {
          if (res.status == 200 && res.data.status == true) {
            this.$emit("confirm");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style></style>
