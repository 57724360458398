<template>
  <v-text-field
    :value="value"
    outlined
    v-bind="$attrs"
    v-on="$listeners"
    background-color="#fff"
    @input="(v) => $emit('input', v)"
    @change="splitName"
    :rules="[(v) => !!v || 'Name is required']"
  ></v-text-field>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: { type: String },
  },
  methods: {
    splitName() {
      let index = this.value.indexOf(" ");
      if (index != -1) {
        this.$emit("split", {
          first_name: this.value.substr(0, index),
          last_name: this.value.substr(index + 1),
        });
      } else {
        this.$emit("split", {
          first_name: this.value,
          last_name: null,
        });
      }
    },
  },
};
</script>

<style>
</style>