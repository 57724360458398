<template>
  <div>
  <v-tooltip right :disabled="!showPreview">
    <template v-slot:activator="{ on, attrs }">
      <span class="breadcrumb-link pointer"
            v-if="item.name"
            v-bind="attrs"
            v-on="on"
            @click="handleCLick">{{ item.name  }}</span>
    </template>
    <v-card v-if="showPreview" flat>
      <v-img v-if="isImage" :src="getPath(path)" max-height="400" max-width="400"></v-img>
      <v-responsive v-else-if="isPdf" max-height="400" max-width="400">
        <embed :src="getPath(path)" type="application/pdf" style="width: 100%; height: 100%;" />
      </v-responsive>
      <v-card-text v-else>Preview not available</v-card-text>
    </v-card>
  </v-tooltip>
  </div>
</template>

<script>

export default {
  props: {
    extension: String,
    path: String,
    item:Object,
  },
  methods: {
    getPath(path){
      return this.s3BucketURL + path;
    },
    handleCLick(){
      this.$emit('handleCLick', this.item);
    }
  },
  computed: {
    isImage() {
      return ['jpg', 'jpeg', 'png', 'gif','svg'].includes(this.extension.toLowerCase());
    },
    isPdf() {
      return this.extension.toLowerCase() === 'pdf';
    },
    showPreview() {
      return this.isImage || this.isPdf;
    },
  },
};
</script>

<style scoped>
/* Additional styling if needed */
</style>
