<template>
  <v-dialog v-model="RenameMedialModal" scrollable width="600px" persistent>
    <v-form ref="form" v-model="valid">
      <v-card class="card-rounded-bottom" style="min-height: 200px">
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold"
                         text="Upload" style="color: black">
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row class="d-flex align-center justify-center">
            <v-col md="8">
              <label for="">Name</label>
              <v-text-field
                  v-model="name"
                  outlined
                  required
                  :rules="[(v) => !!v || 'Name is required']"
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
              ></v-text-field>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions class="card-footer">
          <div class="d-flex w-full justify-end align-center mt-0">
            <v-btn
                elevation="0"
                class="ma-2 white--text blue-color"
                @click="save"
            >Update
            </v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  name: "MediaRenameModal",
  components: {SvgIcon},
  props: {
    RenameMedialModal: {type: Boolean, default: false},
    clearModal: {type: Boolean, default: false},
    fileId: {type: Number, required: false},
    fileName: {type: String, required: false},
  },
  watch: {
    clearModal() {
      this.name = null;
    },
    RenameMedialModal(val){
      if(val){
        this.name = this.fileName;
      }else{
        this.name = null;
      }
    }
  },
  data() {
    return {
      valid: false,
      name: null,
    }
  },
  methods: {
    close() {
      this.$refs.form.resetValidation();
      this.$emit("closeRenameMedialModal");
    },
    save() {
      this.$emit("save", {name: this.name, file_id: this.fileId});
    }
  },

}
</script>

<style scoped>

</style>